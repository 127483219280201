import React, { useEffect, useContext, useState } from 'react';
import '../../assets/styles/Wallet.css';
import UserSettingsContext from '../../hooks/UserSettingsContext';
import { useWallet } from '../../hooks/WalletContext';
import RaydiumIcon from '../../components/RaydiumIcon';
import PFunIcon from '../../components/PFunIcon';
import AuthImage from '../../services/AuthImage';
import RuggedIcon from '../../components/RuggedIcon';
import LiquidityIcon from '../../components/LiquidityIcon';
import BurnedIcon from '../../components/BurnedIcon';
import MintIcon from '../../components/MintIcon';
import FreezIcon from '../../components/FreezIcon';
import HolderIcon from '../../components/HolderIcon';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Link from '@mui/material/Link';
import supabase from '../../services/supabase';

const Wallet = ({ theme }) => {
  const { userSettings, userData, userId } = useContext(UserSettingsContext);
  const { walletData, setWalletData, solBalance, coinDetails, fetchWalletData  } = useWallet();
  const [isTrashRotated, setIsTrashRotated] = useState({});
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [snackbarToken, setSnackbarToken] = useState(null); 
  const [copiedTokens, setCopiedTokens] = useState({});
  const [isRotated, setIsRotated] = useState(false);
  const [hasFetchedData, setHasFetchedData] = useState(false);
 
  useEffect(() => {
    if (userData?.uId && !hasFetchedData) {
      handleFetchWalletData(); // Fetch wallet data once
      setHasFetchedData(true); // Set flag to ensure it's fetched only once
    }
  }, [userData?.uId, hasFetchedData]); // Be cautious with the dependencies here
  

  const handleIconClick = (coinDetail) => {
    if (isRotated[coinDetail.mint]) return;
  
    setIsRotated(prevState => ({ ...prevState, [coinDetail.mint]: true }));
    fetchCoinData(coinDetail);
    
    setTimeout(() => {
      setIsRotated(prevState => ({ ...prevState, [coinDetail.mint]: false }));
    }, 1000);
  };  
  const handleTrashClick = async (coinDetail) => {
    // Prevent further actions if the coin is already in rotation (to avoid duplicate clicks)
    if (isTrashRotated[coinDetail.mint]) return;

    // Rotate the icon to indicate processing
    setIsTrashRotated(prevState => ({ ...prevState, [coinDetail.mint]: true }));

    // Transform `coinDetail` into the expected `coin` structure
    const coin = transformCoinDetailToCoin(coinDetail);

    try {
        await handleTrash(coin);
    } finally {
        // Ensure the rotation stops even if an error occurs
        setTimeout(() => {
          setIsTrashRotated(prevState => ({ ...prevState, [coinDetail.mint]: false }));
        }, 1000);
    }
};

const transformCoinDetailToCoin = (coinDetail) => {
    return {
        account: {
            data: {
                parsed: {
                    info: {
                        tokenAmount: {
                            amount: coinDetail.amount // Assuming coinDetail.amount exists
                        },
                        mint: coinDetail.mint
                    }
                }
            }
        },
        details: coinDetail // Store `coinDetail` in `details` for further use
    };
};

const handleTrash = async (coin) => {
    const RCP4_URL = 'https://rcp4.pounce.bot/';
    
    showSnackbar(coin, { status: 'sending', transactionLink: null });
    const sellAmount = parseFloat(coin.account.data.parsed.info.tokenAmount.amount);
   
console.log("sellAmount",sellAmount);
    try {
        const { data: { session } } = await supabase.auth.getSession();
        const jwtToken = session?.access_token;

        let mint = coin.account.data.parsed.info.mint;
        const coinDetail = coin.details || coinDetails[mint];
        console.log("coinDetail remove dust", coinDetail);
        
        if (!coinDetail) {
            throw new Error('Coin details not found');
        }

        const coinData = await fetchCoinData(coinDetail);
        console.log("coinData before payload:", coinData);

        let network = coinDetail.network || coinData.network;
        if (!network) {
            network = (coinDetail.origin === "Pump.fun" && coinDetail.origin_status === 0 || 
                       coinData.origin === "Pump.fun" && coinData.origin_status === 0) ? 2 : 1;
        }

        let pairAddress = coinData.pool_info.address || coinDetail.pool_info.address;
        if ((coinDetail.origin === "Pump.fun" && coinDetail.origin_status === 1) || 
            (coinData.origin === "Pump.fun" && coinData.origin_status === 1)) {
            mint = coinData.pool_info.address || coinDetail.pool_info.address;
        }
        console.log("New mint", mint);

        // Define the request payload
        const payload = {
            userId,
            network,
            mint: coinDetail.mint,
            mintStr: coinDetail.mint,
            amount: sellAmount.toString(), 
            pairAddress,
            currency: userSettings?.expert?.currency || 'SOL' 
        };
        console.log("payload", payload);

        const baseUrl = window.location.hostname === 'localhost' 
            ? 'http://localhost:3001/remove-dust' 
            : 'https://trade.pounce.bot/remove-dust';

        // Send the request to the server
        const response = await fetch(baseUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwtToken}`
            },
            body: JSON.stringify(payload)
        });

        // Parse the server's response
        const result = await response.json();
        console.log('Response from Server:', result);

        if (response.ok && result.success) {
            if (result.confirmation?.transactionSignature) {
                showSnackbar(coin, { status: 'successful', transactionLink: result.confirmation.transactionSignature });
            }

            await refreshWalletDataAfterDelay();
            await closeAccountIfRequired(jwtToken, RCP4_URL);
        } else {
            await refreshWalletDataAfterDelay();
        await closeAccountIfRequired(jwtToken, RCP4_URL);
        }
    } catch (error) {
        console.error('Error sending data to Server:', error);
        await refreshWalletDataAfterDelay();
        await closeAccountIfRequired(jwtToken, RCP4_URL);
    }
};

const refreshWalletDataAfterDelay = async () => {
    await handleFetchWalletData();
    setTimeout(async () => {
        await fetchWalletData();
    }, 5000); 
};

const closeAccountIfRequired = async (jwtToken, RCP4_URL) => {
    console.log("Send close account request");
    const data = { userId };
    const closeResponse = await fetch(RCP4_URL, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    console.log("closeResponse", closeResponse);
};
  

  const handleFetchWalletData = async () => {
    setIsRotated(true); // Start the rotation animation
    //console.log("Fetching wallet data...");
      await fetchWalletData();
    //console.log("Wallet data fetched successfully.");
    setTimeout(() => setIsRotated(false), 1000); // Stop rotation after 1 second
  };

  const mapCoinData = (data) => {
     // Check if the structure is as expected
  if (!data || !data.data || !data.data.data || !data.data.data.token) {
    console.error("Invalid API data structure", data);
    return {}; // Return an empty object or handle the error as needed
  }

  // Correctly accessing the deeply nested token data
  const tokenData = data.data.data.token; 
  
    // Destructure and map the properties
    const {
      address, 
      symbol, 
      name, 
      decimals, 
      logo, 
      liquidity, 
      total_supply, 
      launchpad, 
      launchpad_progress, 
      launchpad_status, 
      top_10_holder_rate, 
      renounced_mint, 
      renounced_freeze_account, 
      burn_ratio, 
      burn_status, 
      rug_ratio, 
      dexscr_ad, 
      dexscr_update_link, 
      holder_count, 
      hot_level, 
      is_honeypot, 
      market_cap, 
      max_supply, 
      net_in_volume_1h, 
      net_in_volume_1m, 
      net_in_volume_5m, 
      net_in_volume_6h, 
      net_in_volume_24h, 
      pool_info, 
      price, 
      price_1h, 
      price_1m, 
      price_5m, 
      price_6h, 
      price_24h, 
      sell_tax, 
      sell_volume_1h, 
      sell_volume_1m, 
      sell_volume_5m, 
      sell_volume_6h, 
      sell_volume_24h, 
      sells_1h, 
      sells_1m, 
      sells_5m, 
      sells_6h, 
      sells_24h, 
      swaps_1h, 
      swaps_1m, 
      swaps_5m, 
      swaps_6h, 
      swaps_24h, 
      volume_1h, 
      volume_1m, 
      volume_5m, 
      volume_6h, 
      volume_24h,
      social_links = {}
    } = tokenData;
  
    // Logging each of the extracted properties
    console.log("Address:", address);
    console.log("Symbol:", symbol);
    console.log("Name:", name);
    // Log more properties as needed...
  
    const extractedData = {
      mint: address,
      ticker: symbol,
      name: name,
      decimals: decimals,
      img: logo,
      liquidity: liquidity,
      total_supply: total_supply,
      origin: launchpad,
      launchpad_progress: launchpad_progress,
      origin_status: launchpad_status,
      top_10_holder_rate: top_10_holder_rate * 100,
      renounced_mint: renounced_mint,
      renounced_freeze_account: renounced_freeze_account,
      burn_ratio: burn_ratio || undefined,
      burn_status: burn_status,
      rug_ratio: rug_ratio !== null ? 1 : null,
      dexscr_ad: dexscr_ad,
      dexscr_update_link: dexscr_update_link,
      holder_count: holder_count,
      hot_level: hot_level,
      is_honeypot: is_honeypot,
      market_cap: market_cap,
      max_supply: max_supply,
      net_in_volume_1h: net_in_volume_1h,
      net_in_volume_1m: net_in_volume_1m,
      net_in_volume_5m: net_in_volume_5m,
      net_in_volume_6h: net_in_volume_6h,
      net_in_volume_24h: net_in_volume_24h,
      pool_info: pool_info,
      price: price,
      price_1h: price_1h,
      price_1m: price_1m,
      price_5m: price_5m,
      price_6h: price_6h,
      price_24h: price_24h,
      sell_tax: sell_tax,
      sell_volume_1h: sell_volume_1h,
      sell_volume_1m: sell_volume_1m,
      sell_volume_5m: sell_volume_5m,
      sell_volume_6h: sell_volume_6h,
      sell_volume_24h: sell_volume_24h,
      sells_1h: sells_1h,
      sells_1m: sells_1m,
      sells_5m: sells_5m,
      sells_6h: sells_6h,
      sells_24h: sells_24h,
      swaps_1h: swaps_1h,
      swaps_1m: swaps_1m,
      swaps_5m: swaps_5m,
      swaps_6h: swaps_6h,
      swaps_24h: swaps_24h,
      volume_1h: volume_1h,
      volume_1m: volume_1m,
      volume_5m: volume_5m,
      volume_6h: volume_6h,
      volume_24h: volume_24h,
      network: (launchpad === "Pump.fun" && launchpad_status === 0) ? 2 : 1,
    };
  
    // Logging the extractedData before returning it
    console.log("extractedData:", extractedData);
  
    // Mapping social media links from social_links
    if (social_links.twitter_username) extractedData.twitter = `https://x.com/${social_links.twitter_username}`;
    if (social_links.website) extractedData.website = social_links.website;
    if (social_links.telegram) extractedData.telegram = social_links.telegram;
    if (social_links.bitbucket) extractedData.bitbucket = social_links.bitbucket;
    if (social_links.discord) extractedData.discord = social_links.discord;
    if (social_links.facebook) extractedData.facebook = social_links.facebook;
    if (social_links.github) extractedData.github = social_links.github;
    if (social_links.instagram) extractedData.instagram = social_links.instagram;
    if (social_links.linkedin) extractedData.linkedin = social_links.linkedin;
    if (social_links.medium) extractedData.medium = social_links.medium;
    if (social_links.reddit) extractedData.reddit = social_links.reddit;
    if (social_links.tiktok) extractedData.tiktok = social_links.tiktok;
    if (social_links.youtube) extractedData.youtube = social_links.youtube;
  
    // Logging the final extracted data
    console.log("Final extractedData:", extractedData);
  
    return extractedData;
  };

const fetchCoinData = async (coinDetail) => {
  console.log("fetchCoinData start");
  try {
    const contractAddress = coinDetail.mint || coinDetail.account.data.parsed.info.mint;

    if (!contractAddress) {
      console.error("contractAddress is undefined for this coin:", coinDetail);
      return;
    }

    // Send message to content script
    window.postMessage({ type: "GET_TOKEN_INFO", contractAddress }, "*");
    console.log("postMessage");
    // Listen for response from content script
    return new Promise((resolve, reject) => {
      //console.log("new Promise");
      const handleMessage = (event) => {
        if (event.data.type && event.data.type === "TOKEN_INFO_RESPONSE") {
          window.removeEventListener("message", handleMessage);
          console.log("window.removeEventListener");
          if (event.data.data.success) {
            console.log("event.data.data.success");
            console.log("event.data.data:", event.data.data);
            const mappedData = mapCoinData(event.data.data);
            console.log("mappedData:", mappedData);
            setWalletData(prevWalletData =>
              prevWalletData.map(coin =>
                coin.account.data.parsed.info.mint === mappedData.mint
                  ? { ...coin, details: { ...coin.details, ...mappedData } }
                  : coin
              )
            );
            resolve(mappedData);
          } else {
            console.error("Error fetching token info:", event.data.data.error);
            resolve(null); // Return null on error instead of rejecting
          }
        }
      };
      window.addEventListener("message", handleMessage);
      console.log(" window.addEventListener");
    });
  } catch (error) {
    console.error('Error fetching coin data:', error.message);
    return null;
  }
};


  
  const handleSell = async (coin, percent) => {
    //console.log('Sell initiated for:', coin, 'Percent:', percent);
    const RCP4_URL = 'https://rcp4.pounce.bot/';
    
    const RCP4_URL2 = window.location.hostname === 'localhost' 
        ? 'http://localhost:3001/close-account' 
        : 'https://rcp4.pounce.bot/';

    // Show initial snackbar message
    showSnackbar(coin, { status: 'sending', transactionLink: null });
  
      // Calculate the sell amount based on the percentage and round it to the nearest integer
      const sellAmount = Math.round(coin.account.data.parsed.info.tokenAmount.amount * (percent / 100)); // Use amount field and convert to smallest unit
      //console.log("sellAmount", sellAmount);
    try {
      // Get the JWT token for authentication
      const { data: { session } } = await supabase.auth.getSession();
      const jwtToken = session?.access_token;
      
       // Fetch additional details from coinDetails using the mint address
      let mint = coin.account.data.parsed.info.mint;
      const coinDetail = coin.details || coinDetails[mint];
      console.log("coinDetail SELL", coinDetail);
      //console.log("mint", mint);
      //console.log("coinDetail", coinDetail);
      if (!coinDetail) {
        throw new Error('Coin details not found');
      }
  
    const coinData = await fetchCoinData(coinDetail);
    console.log("coinData before payload:", coinData);
      let network = coinDetail.network || coinData.network;
     
      if (!network) {
        if (coinDetail.origin === "Pump.fun" && coinDetail.origin_status === 0 || coinData.origin === "Pump.fun" && coinData.origin_status === 0) {
          network = 2;
        } else {
          network = 1;
        }
      }
      
       let pairAddress = coinData.pool_info.address || coinDetail.pool_info.address;
        console.log("set new pairAddress", pairAddress)
      
      console.log("New pairAddress", pairAddress)
      console.log("after ifNetwork")
      console.log("coinDetail",coinDetail);
      console.log("coinDetail pool_info address",coinDetail.pool_info.address);
      console.log("coinData pool_info address",coinData.pool_info.address);
      if (coinDetail.origin === "Pump.fun" && coinDetail.origin_status === 1 || coinData.origin === "Pump.fun" && coinData.origin_status === 1) {
        console.log("before set new mint", mint)
        mint = coinData.pool_info.address || coinDetail.pool_info.address;
        console.log("set new mint", mint)
      }
      console.log("New mint", mint)
      // Define the request payload
      const payload = {
        userId,
        network: network, // Get network from additional coin details // coinDetail.mint
        mint: coinDetail.mint, // Use mint as the unique identifier for the token coinData.mint
        mintStr: coinDetail.mint, // Use mint again as mintStr if required
        amount: sellAmount,
        pairAddress: pairAddress,
        currency: userSettings?.expert?.currency || 'SOL' // Use 'SOL' as default currency if userSettings?.expert?.currency is undefined
      };
      console.log("payload",payload);
      const baseUrl = window.location.hostname === 'localhost' 
          ? 'http://localhost:3001/sell' 
          : 'https://trade.pounce.bot/sell';
          const serverUrl = "https://trade.pounce.bot/sell";
          console.log("tradingUrl",baseUrl);
        // Send the request to the /sell endpoint
        const response = await fetch(baseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify(payload)
      });
  
      // Parse the response from the server
      const result = await response.json();
      console.log('Response from Server:', result);
  
      // Handle success and failure cases
      if (response.ok && result.success) {
        if (result.confirmation.transactionSignature) {
        showSnackbar(coin, { status: 'successful', transactionLink: result.confirmation.transactionSignature });
        }
        setTimeout(async () => {
        await handleFetchWalletData();
        setTimeout(async () => {
          await fetchWalletData();
        }, 5000); 
      }, 10000); 
        if (percent === 100) {  // Only call if the sell is 100%
          console.log("Send close account request")
          const data = {userId: userId};
          const closeResponse = await fetch(RCP4_URL, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${jwtToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          });
          console.log("closeResponse",closeResponse);
        }
      } else {
        //showSnackbar(coin, { status: 'failed' });
        
        setTimeout(async () => {
          await handleFetchWalletData();
          setTimeout(async () => {
            await fetchWalletData();
          }, 5000); 
        }, 10000); 
        
      }
    } catch (error) {
      console.error('Error sending data to Server:', error);
      //showSnackbar(coin, { status: 'failed' });
      
      setTimeout(async () => {
        await handleFetchWalletData();
        setTimeout(async () => {
          await fetchWalletData();
        }, 5000); 
      }, 10000); 
      
    }
  };  

  const handleCopy = (token) => {
    navigator.clipboard.writeText(token.mint);
    setCopiedTokens(prevState => ({ ...prevState, [token.mint]: true }));
  
    setTimeout(() => {
      setCopiedTokens(prevState => ({ ...prevState, [token.mint]: false }));
    }, 500); // Duration of the animation
  };

  const showSnackbar = (token, { status, transactionLink }) => {
    //console.log('Show Snackbar:', status, transactionLink);
    
    const statusMap = {
      sending: { severity: 'info', color: 'orange' },
      successful: { severity: 'success', color: 'green' },
      failed: { severity: 'error', color: 'red' },
    };
  
    const { severity } = statusMap[status] || {};
  
    // Example code to display a snackbar with a clickable link
const solscanLink = transactionLink 
? <Link href={`https://solscan.io/tx/${transactionLink}`} target="_blank" rel="noopener" sx={{ color: 'white' }}>{transactionLink}</Link> 
: '';

// Construct a safe message as a JSX element
const message = (
<>
    Transaction {status}
    {transactionLink ? ` - Link: ` : ''}
    {solscanLink}
</>
);
   
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarToken(token); // Set the current token
    setSnackbarOpen(true);
  
    // Automatically close the Snackbar after 5 seconds for successful and failed states
      setTimeout(() => {
        //console.log('Closing Snackbar after 5 seconds');
        setSnackbarOpen(false);
      }, 5000); // 5 seconds
  };

  // Sort walletData by token amount in descending order
  const sortedWalletData = [...walletData].sort((a, b) => {
    return b.account.data.parsed.info.tokenAmount.uiAmount - a.account.data.parsed.info.tokenAmount.uiAmount;
  });

  return (
    <div className={`coins-wallet ${theme}`}>
      <div className="wallet-balance">
        <span><b>Wallet: {solBalance.toFixed(6)} {userSettings?.expert?.currency || 'SOL'}</b></span>
        <a className="wallet-link-wallet" href="#!" onClick={handleFetchWalletData} title={`Refresh Wallet`}>
          <svg className={`wallet-icon ${isRotated ? 'rotate-animation' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor" d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z"/>
          </svg>
        </a>
      </div>
      {error && <div className="error-message">{error}</div>} {/* Render error message */}

      {/* Iterate through the walletData to display each coin */}
      {sortedWalletData.map(coin => {
        const mint = coin.account.data.parsed.info.mint;

         // Skip SOL, WSOL, USDC, and tokens with a value of 0
        if (
          mint === "So11111111111111111111111111111111111111112" || 
          mint === "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E" || 
          mint === "Es9vMFrzaCERk9Qmhipts7KohugqJUkUxePKuA8asf7z" ||
          coin.account.data.parsed.info.tokenAmount.uiAmount <= 0
        ) {
          return null;
        }

        const coinDetail = coin.details || coinDetails[mint];
        //console.log("coinDetail",coinDetail);
        //console.log("coin.details",coin.details);
        if (!coinDetail) {
          return null; // Skip if details are not fetched yet
        }

        return (
          <div key={mint} className="coin-tile">
            <div className="token-image-container-wallet">
              {(coinDetail.img && (
                  <div className="image-wrapper">
                    {coinDetail.network === 1 && <RaydiumIcon size={20} className="network-icon" title="Raydium Token" />}
                    {coinDetail.network === 2 && <PFunIcon size={16} className="network-icon" title="Pump.Fun Token" />}
                    <AuthImage
                      title={coinDetail.name}
                      key={coinDetail.img} 
                      src={coinDetail.img}
                      alt={coinDetail.name || mint}
                      className="token-image"
                      bypassCache={true}
                      style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px', alignSelf: 'center' }}
                    />
                  </div>
                )
              )}
            </div>
            <span style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '5px' }}>
            {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 6 }).format(coin.account.data.parsed.info.tokenAmount.uiAmount)}  &nbsp;{coinDetail.ticker}{/* Token amount displayed here */}
              </span>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                <span title={coinDetail.name} style={{ fontWeight: 'bold' }}>
                  {coinDetail.name ? `${coinDetail.name.slice(0, 10)} ` : ''}
                </span>
                <span>
                  &nbsp;{coinDetail.ticker ? `(${coinDetail.ticker.startsWith('$') ? coinDetail.ticker : `$${coinDetail.ticker}`}) ` : ''}&nbsp;
                </span>
                {coinDetail.rug_ratio === 1 && (
                  <RuggedIcon size={15} title="Creator Rugged other Coins!" />
                )}
                {coinDetail.top_10_holder_rate != null && coinDetail.top_10_holder_rate !== 100 && coinDetail.top_10_holder_rate !== 0 && (
                  coinDetail.top_10_holder_rate > 20 ? (
                    <HolderIcon size={15} title={`Top 10 holders control ${coinDetail.top_10_holder_rate.toFixed(2)}% of the tokens!`} fill="red" />
                  ) : coinDetail.top_10_holder_rate > 10 ? (
                    <HolderIcon size={15} title={`Top 10 holders control ${coinDetail.top_10_holder_rate.toFixed(2)}% of the tokens!`} fill="orange" />
                  ) : (
                    <HolderIcon size={15} title={`Top 10 holders control ${coinDetail.top_10_holder_rate.toFixed(2)}% of the tokens!`} fill="green" />
                  )
                )}
                {coinDetail.renounced_mint === 0 && (
                  <MintIcon size={15} title="Mint Authority NOT renounced!" />
                )}
                {coinDetail.renounced_mint === 1 && (
                  <MintIcon size={15} title="Mint Authority renounced!" fill="green" />
                )}
                {coinDetail.renounced_freeze_account === 0 && (
                  <FreezIcon size={15} title="Freeze Authority NOT Revoked!" />
                )}
                {coinDetail.renounced_freeze_account === 1 && (
                  <FreezIcon size={15} title="Freeze Authority Revoke!" fill="green" />
                )}
                {(coinDetail.burn_status === "none" || coinDetail.burn_status === "") && (
                  <BurnedIcon size={15} title="LP token NOT burned!" fill="red" />
                )}
                {coinDetail.burn_status === "burn" && (
                  <BurnedIcon size={15} title="LP token burned!" fill="green" />
                )}
                {coinDetail.liquidity != null && (
                  coinDetail.liquidity < 5000 ? (
                    <LiquidityIcon size={15} title={`Very low liquidity $${Math.floor(coinDetail.liquidity)}`} fill="red" />
                  ) : coinDetail.liquidity < 10000 ? (
                    <LiquidityIcon size={15} title={`Low liquidity $${Math.floor(coinDetail.liquidity)}`} fill="orange" />
                  ) : coinDetail.liquidity > 50000 ? (
                    <LiquidityIcon size={15} title={`Good liquidity $${Math.floor(coinDetail.liquidity)}`} fill="green" />
                  ) : (
                    <LiquidityIcon size={15} title={`Moderate liquidity $${Math.floor(coinDetail.liquidity)}`} fill="lightgreen"/>
                  )
                )}
              </div>
              <div className="mintStr" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span
                  title={`Click to copy address: ${mint}`}
                  onClick={() => handleCopy({ mint })}
                  style={{ cursor: 'pointer', fontSize: '14px', paddingLeft: '0rem', marginRight: '1rem' }}
                  className={`mint-address ${copiedTokens[mint] ? 'copied' : ''}`}
                >
                  {mint ? `${mint.slice(0, 4)}...${mint.slice(-4)}` : ''}
                </span>
                <div className="social-links">
                  {coinDetail.twitter && (
                    <a href={coinDetail.twitter} target="_blank" rel="noopener noreferrer" className={`token-link ${theme}`} title={`Visit token twitter: ${coinDetail.twitter}`}>
                      <svg className="social-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" />
                      </svg>
                    </a>
                  )}
                  {coinDetail.telegram && (
                    <a href={coinDetail.telegram} target="_blank" rel="noopener noreferrer" className={`token-link ${theme}`} title={`Visit token telegram: ${coinDetail.telegram}`}>
                      <svg className="social-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm121.8 169.9l-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z" />
                      </svg>
                    </a>
                  )}
                  {coinDetail.website && (
                    <a href={coinDetail.website} target="_blank" rel="noopener noreferrer" className={`token-link ${theme}`} title={`Visit token website: ${coinDetail.website}`}>
                      <svg className="social-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z" />
                      </svg>
                    </a>
                  )}
                  <a href={coinDetail.network === 1 ? `https://dexscreener.com/solana/${mint}` : `https://pump.fun/${mint}`} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className={`token-link ${theme}`} 
                    title={coinDetail.network === 1 ? "View token on DexScreener" : "View token on Pump.Fun"}>
                    <svg className="social-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path fill="currentColor" d="M432 320H400a16 16 0 0 0 -16 16V448H64V128H208a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16H48A48 48 0 0 0 0 112V464a48 48 0 0 0 48 48H400a48 48 0 0 0 48-48V336A16 16 0 0 0 432 320zM488 0h-128c-21.4 0-32.1 25.9-17 41l35.7 35.7L135 320.4a24 24 0 0 0 0 34L157.7 377a24 24 0 0 0 34 0L435.3 133.3 471 169c15 15 41 4.5 41-17V24A24 24 0 0 0 488 0z" />
                    </svg>
                  </a>
                  <a className={`token-link ${theme}`} href="#!" onClick={() => handleIconClick(coinDetail)} title={`Refresh Coin Data & Rug Check`}>
                              <svg className={`social-icons ${isRotated[coinDetail.mint] ? 'rotate-animation' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z"/>
                              </svg>
                            </a>
                 <a className={`token-link ${theme}`} href="#!" onClick={() => handleTrashClick(coinDetail)} title={`Trash Coin Dust`}>
                  <svg className={`social-icons ${isTrashRotated[coinDetail.mint] ? 'rotate-animation' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path fill="currentColor" d="M170.5 51.6L151.5 80l145 0-19-28.4c-1.5-2.2-4-3.6-6.7-3.6l-93.7 0c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80 368 80l48 0 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-8 0 0 304c0 44.2-35.8 80-80 80l-224 0c-44.2 0-80-35.8-80-80l0-304-8 0c-13.3 0-24-10.7-24-24S10.7 80 24 80l8 0 48 0 13.8 0 36.7-55.1C140.9 9.4 158.4 0 177.1 0l93.7 0c18.7 0 36.2 9.4 46.6 24.9zM80 128l0 304c0 17.7 14.3 32 32 32l224 0c17.7 0 32-14.3 32-32l0-304L80 128zm80 64l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16z"/>
                     </svg>
                  </a>
                            
                </div>
                <Snackbar
                    open={snackbarOpen}// Check that the token matches
                    autoHideDuration={snackbarSeverity !== 'info' ? 5000 : null} // Handle sending status
                    onClose={() => setSnackbarOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  >
                    <Alert
                      onClose={() => setSnackbarOpen(false)}
                      severity={snackbarSeverity}
                      iconMapping={{
                        success: <CheckCircleOutline fontSize="inherit" style={{ color: 'white' }} />,
                        error: <ErrorOutline fontSize="inherit" style={{ color: 'white' }} />,
                        info: <InfoOutlined fontSize="inherit" style={{ color: 'white' }} />,
                      }}
                      sx={{
                        backgroundColor:
                          snackbarSeverity === 'success'
                            ? 'green'
                            : snackbarSeverity === 'error'
                            ? 'red'
                            : 'orange',
                        color: '#fff',
                      }}
                    >
                      {snackbarMessage}
                    </Alert>
                  </Snackbar>
              </div>
              <div className="button-group">
                {(userSettings?.quickSell?.sellAmounts || [25, 50, 75, 100]).map((percent) => (
                  <button key={`${mint}-${percent}`} onClick={() => handleSell(coin, percent)}>
                    Sell {percent}%
                  </button>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Wallet;

import React from 'react';
import '../assets/styles/Sidebar.css';

function Sidebar({
    autoBuySwitch,
    copyTradeSwitch,
    newCoinsSwitch,
    upcomingCoinsSwitch,
    trackerSwitch,
    trendingSwitch,
    walletSwitch,
    handleAutoBuyToggle,
    handleCopyTradeToggle,
    handleNewCoinsToggle,
    handleUpcomingCoinsToggle,
    handleTrackerToggle,
    handleTrendingToggle,
    handleWalletToggle,
    userData,
    isOpen,
    theme,
    onClose
}) {
    return (
        <div className={`sidebar ${isOpen ? 'open' : ''} ${theme}`}>
            <button className="close-button" onClick={onClose} title={`Close Menu`}>×</button>
            <h3>Module</h3>
            <div className="switch-container">
                <label className="switch">
                    <input type="checkbox" checked={autoBuySwitch} onChange={handleAutoBuyToggle} />
                    <div className="slider round"></div>
                </label>
                <span className="switch-name">Auto Buy</span>
            </div>
            <div className="switch-container">
                <label className="switch">
                    <input type="checkbox" checked={copyTradeSwitch} onChange={handleCopyTradeToggle} />
                    <div className="slider round"></div>
                </label>
                <span className="switch-name">Copy Trading</span>
            </div>
            <div className="switch-container">
                <label className="switch">
                    <input type="checkbox" checked={newCoinsSwitch} onChange={handleNewCoinsToggle} />
                    <div className="slider round"></div>
                </label>
                <span className="switch-name">New</span>
            </div>
                <div className="switch-container">
                    <label className="switch">
                        <input type="checkbox" checked={upcomingCoinsSwitch} onChange={handleUpcomingCoinsToggle} />
                        <div className="slider round"></div>
                    </label>
                    <span className="switch-name">Upcoming</span>
                </div>
            <div className="switch-container">
                <label className="switch">
                    <input type="checkbox" checked={trendingSwitch} onChange={handleTrendingToggle} />
                    <div className="slider round"></div>
                </label>
                <span className="switch-name">Trending</span>
            </div>
            <div className="switch-container">
                <label className="switch">
                    <input type="checkbox" checked={trackerSwitch} onChange={handleTrackerToggle} />
                    <div className="slider round"></div>
                </label>
                <span className="switch-name">Tracker</span>
            </div>
            <div className="switch-container">
                <label className="switch">
                    <input type="checkbox" checked={walletSwitch} onChange={handleWalletToggle} />
                    <div className="slider round"></div>
                </label>
                <span className="switch-name">Wallet</span>
            </div>
            <a className="docs-link" href="https://documentation.example.com" target="_blank" rel="noopener noreferrer" title={`Read more about the Bot settings and functions`}>
              <h3>Documentation <svg className="social-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path fill="currentColor" d="M432 320H400a16 16 0 0 0 -16 16V448H64V128H208a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16H48A48 48 0 0 0 0 112V464a48 48 0 0 0 48 48H400a48 48 0 0 0 48-48V336A16 16 0 0 0 432 320zM488 0h-128c-21.4 0-32.1 25.9-17 41l35.7 35.7L135 320.4a24 24 0 0 0 0 34L157.7 377a24 24 0 0 0 34 0L435.3 133.3 471 169c15 15 41 4.5 41-17V24A24 24 0 0 0 488 0z" />
                        </svg></h3>
            </a>
        </div>
    );
}

export default Sidebar;

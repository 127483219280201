import React, { useState, useEffect, useRef, useContext } from 'react';
import supabase from '../../services/supabase';
import AuthImage from '../../services/AuthImage';
import '../../assets/styles/NewCoins.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useSSE from '../../hooks/useSSE';
import Modal from 'react-modal';
import SettingsForm from '../../forms/solana/SettingsForm';
import SettingsTabContext from '../../hooks/SettingsTabContext';
import UserSettingsContext from '../../hooks/UserSettingsContext';
import RuggedIcon from '../../components/RuggedIcon';
import BurnedIcon from '../../components/BurnedIcon';
import MintIcon from '../../components/MintIcon';
import FreezIcon from '../../components/FreezIcon';
import HolderIcon from '../../components/HolderIcon';
import LiquidityIcon from '../../components/LiquidityIcon';
import PFunIcon from '../../components/PFunIcon';
import RaydiumIcon from '../../components/RaydiumIcon';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Link from '@mui/material/Link';
import { useBuy } from '../../hooks/useBuy';

const Tracker = ({ theme, activeModal, setActiveModal, onPin, activeModuleCount }) => {
  const [transactionStatus, setTransactionStatus] = useState(null);
  const nodeRef = useRef(null);
  const [imgPrefixes, setImgPrefixes] = useState({});
  const [top3Active, setTop3Active] = useState(true);
  const tokens = useSSE(['Tracked']);
  const { userSettings, userId } = useContext(UserSettingsContext);
  const { setActiveTab } = useContext(SettingsTabContext);
  const wideClassThreshold = 3;
  const [tokensWithCoinData, setTokensWithCoinData] = useState([]);
  const [isRotating, setIsRotating] = useState(false);
  const [rotatingTokens, setRotatingTokens] = useState({});
  const [copied, setCopied] = useState(false);
  const [copiedTokens, setCopiedTokens] = useState({});
  const { handleBuy, snackbarOpen, snackbarMessage, snackbarSeverity, setSnackbarOpen } = useBuy();

  useEffect(() => {
    if (Notification.permission === 'default') {
      Notification.requestPermission();
    }
  }, []);
  
  useEffect(() => {
    const fetchDataForTokens = async () => {
      const updatedTokens = await Promise.all(
        tokens.map(async (token) => {
             // Check if the token is already in the list with data
            const existingToken = tokensWithCoinData.find(t => t.mint === token.mint);
            
            if (existingToken) {
              return existingToken; // Skip fetch and use the existing token data
            }
          const coinData = await fetchCoinData(token);
          return { ...token, ...coinData };
        })
      );

      setTokensWithCoinData((prevTokens) => {
        const newTokens = [...updatedTokens, ...prevTokens]; // Add new tokens on top
        const uniqueTokens = Array.from(new Set(newTokens.map(t => t.mint))) // Ensure no duplicates
          .map(mint => newTokens.find(t => t.mint === mint));

        return uniqueTokens.slice(0, 30); // Limit to last 30 tokens
      });
    };

    if (tokens.length) {
      fetchDataForTokens();
    }
  }, [tokens]);

  useEffect(() => {
    if (tokensWithCoinData.length > 0) {
      const latestToken = tokensWithCoinData[0]; // The newest token is at the top

      if ("Notification" in window && Notification.permission === 'granted') {
        new Notification('New Token Added!', {
          body: `${latestToken.name} (${latestToken.ticker}) has been added.`,
          icon: latestToken.img,
        });
      }
    }
  }, [tokensWithCoinData]);

  const fetchCoinData = async (token) => {
    console.log("fetchCoinData start");
    try {
      const contractAddress = token.mint;

      if (!contractAddress) {
        console.error("contractAddress is undefined for this token:", token);
        return;
      }

      // Send message to content script
      window.postMessage({ type: "GET_TOKEN_INFO", contractAddress }, "*");
      console.log("postMessage");
      // Listen for response from content script
      return new Promise((resolve) => {
        console.log("new Promise");
        const handleMessage = (event) => {
          if (event.data.type && event.data.type === "TOKEN_INFO_RESPONSE") {
            window.removeEventListener("message", handleMessage);
            console.log("window.removeEventListener");
            if (event.data.data.success) {
              console.log("event.data.data.success");
              console.log("event.data.data:", event.data.data);
              const mappedData = mapCoinData(event.data.data);
              console.log("mappedData:", mappedData);
              resolve(mappedData);
            } else {
              console.error("Error fetching token info:", event.data.data.error);
              resolve(null); // Return null on error instead of rejecting
            }
          }
        };
        window.addEventListener("message", handleMessage);
        console.log("window.addEventListener");
      });
    } catch (error) {
      console.error('Error fetching coin data:', error.message);
      return null;
    }
  };

  const mapCoinData = (data) => {
    // Check if the structure is as expected
    if (!data || !data.data || !data.data.data || !data.data.data.token) {
      console.error("Invalid API data structure", data);
      return {}; // Return an empty object or handle the error as needed
    }

    // Correctly accessing the deeply nested token data
    const tokenData = data.data.data.token;

    const {
      address,
      symbol,
      name,
      decimals,
      logo,
      liquidity,
      total_supply,
      launchpad,
      launchpad_progress,
      launchpad_status,
      top_10_holder_rate,
      renounced_mint,
      renounced_freeze_account,
      burn_ratio,
      burn_status,
      rug_ratio,
      dexscr_ad,
      dexscr_update_link,
      holder_count,
      hot_level,
      is_honeypot,
      market_cap,
      max_supply,
      net_in_volume_1h,
      net_in_volume_1m,
      net_in_volume_5m,
      net_in_volume_6h,
      net_in_volume_24h,
      pool_info,
      price,
      price_1h,
      price_1m,
      price_5m,
      price_6h,
      price_24h,
      sell_tax,
      sell_volume_1h,
      sell_volume_1m,
      sell_volume_5m,
      sell_volume_6h,
      sell_volume_24h,
      sells_1h,
      sells_1m,
      sells_5m,
      sells_6h,
      sells_24h,
      swaps_1h,
      swaps_1m,
      swaps_5m,
      swaps_6h,
      swaps_24h,
      volume_1h,
      volume_1m,
      volume_5m,
      volume_6h,
      volume_24h,
      socials = {}
    } = tokenData;

    const extractedData = {
      mint: address,
      ticker: symbol,
      name: name,
      decimals: decimals,
      img: logo,
      liquidity: liquidity,
      total_supply: total_supply,
      origin: launchpad,
      launchpad_progress: launchpad_progress,
      origin_status: launchpad_status,
      top_10_holder_rate: top_10_holder_rate * 100,
      renounced_mint: renounced_mint,
      renounced_freeze_account: renounced_freeze_account,
      burn_ratio: burn_ratio || undefined,
      burn_status: burn_status,
      rug_ratio: rug_ratio !== null ? 1 : null,
      dexscr_ad: dexscr_ad,
      dexscr_update_link: dexscr_update_link,
      holder_count: holder_count,
      hot_level: hot_level,
      is_honeypot: is_honeypot,
      market_cap: market_cap,
      max_supply: max_supply,
      net_in_volume_1h: net_in_volume_1h,
      net_in_volume_1m: net_in_volume_1m,
      net_in_volume_5m: net_in_volume_5m,
      net_in_volume_6h: net_in_volume_6h,
      net_in_volume_24h: net_in_volume_24h,
      pool_info: pool_info,
      price: price,
      price_1h: price_1h,
      price_1m: price_1m,
      price_5m: price_5m,
      price_6h: price_6h,
      price_24h: price_24h,
      sell_tax: sell_tax,
      sell_volume_1h: sell_volume_1h,
      sell_volume_1m: sell_volume_1m,
      sell_volume_5m: sell_volume_5m,
      sell_volume_6h: sell_volume_6h,
      sell_volume_24h: sell_volume_24h,
      sells_1h: sells_1h,
      sells_1m: sells_1m,
      sells_5m: sells_5m,
      sells_6h: sells_6h,
      sells_24h: sells_24h,
      swaps_1h: swaps_1h,
      swaps_1m: swaps_1m,
      swaps_5m: swaps_5m,
      swaps_6h: swaps_6h,
      swaps_24h: swaps_24h,
      volume_1h: volume_1h,
      volume_1m: volume_1m,
      volume_5m: volume_5m,
      volume_6h: volume_6h,
      volume_24h: volume_24h,
      network: (launchpad === "Pump.fun" && launchpad_status === 0) ? 2 : 1,
    };

    // Mapping social media links
    if (socials.twitter_username) extractedData.twitter = `https://x.com/${socials.twitter_username}`;
    if (socials.website) extractedData.website = socials.website;
    if (socials.telegram) extractedData.telegram = socials.telegram;
    if (socials.bitbucket) extractedData.bitbucket = socials.bitbucket;
    if (socials.discord) extractedData.discord = socials.discord;
    if (socials.facebook) extractedData.facebook = socials.facebook;
    if (socials.github) extractedData.github = socials.github;
    if (socials.instagram) extractedData.instagram = socials.instagram;
    if (socials.linkedin) extractedData.linkedin = socials.linkedin;
    if (socials.medium) extractedData.medium = socials.medium;
    if (socials.reddit) extractedData.reddit = socials.reddit;
    if (socials.tiktok) extractedData.tiktok = socials.tiktok;
    if (socials.youtube) extractedData.youtube = socials.youtube;

    return extractedData;
  };

  const handleTop3Change = (e) => {
    const isChecked = e.target.checked;
    setTop3Active(isChecked);
  };

  const handleCopy = (token) => {
    navigator.clipboard.writeText(token.mint);
    setCopiedTokens(prevState => ({ ...prevState, [token.mint]: true }));

    setTimeout(() => {
      setCopiedTokens(prevState => ({ ...prevState, [token.mint]: false }));
    }, 500);
  };
  const handleCopy2 = (token) => {
    navigator.clipboard.writeText(token.trackedWalletAccount);
    setCopiedTokens(prevState => ({ ...prevState, [token.trackedWalletAccount]: true }));
  
    setTimeout(() => {
      setCopiedTokens(prevState => ({ ...prevState, [token.trackedWalletAccount]: false }));
    }, 500);
  };

  const handleRemove = (token) => {
    setTokensWithCoinData((prevTokens) => prevTokens.filter(t => t.mint !== token.mint));
  };

  const openSettings = () => {
    setActiveTab('wallet-tracker');
    setActiveModal('settings');
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  const handleIconClick = (token) => {
    if (rotatingTokens[token.mint]) return;
  
    setRotatingTokens(prevState => ({ ...prevState, [token.mint]: true }));
    fetchCoinData(token);
    
    setTimeout(() => {
      setRotatingTokens(prevState => ({ ...prevState, [token.mint]: false }));
    }, 1000);
  };  

  if (!tokensWithCoinData.length) {
    return (
      <>
        <div className={`new-token ${theme}`}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input type="checkbox" checked={top3Active} onChange={handleTop3Change} />
            <span style={{ marginRight: '10px' }}>Top3 Tracked Wallets</span>
            <a className="settings-link" href="#!" onClick={openSettings} title={`Add Wallets to tracker`}>
              <svg className="icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor" d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM200 344V280H136c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H248v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/>
              </svg>
            </a>
          </div>
        </div>
        <div className={`new-token ${theme}`}>No new transactions in tracked wallets...</div>
        <Modal
          isOpen={activeModal === 'settings'}
          onRequestClose={closeModal}
          contentLabel="Settings Modal"
          overlayClassName="customOverlay"
          className="customContent"
          style={{ overlay: { top: '0px'}, content: { top: '50px' } }}
        >
          <SettingsForm theme={theme} onClose={closeModal} />
        </Modal>
      </>
    );
  }

  return (
    <>
      <div className={`new-token ${theme}`}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input type="checkbox" checked={top3Active} onChange={handleTop3Change} />
          <span style={{ marginRight: '10px' }}>Top3 Tracked Wallets</span>
          <a className="settings-link" href="#!" onClick={openSettings} title={`Add Wallets to tracker`}>
            <svg className="icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path fill="currentColor" d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM200 344V280H136c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H248v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/>
            </svg>
          </a>
        </div>
      </div>
      <div className={`new-token ${theme}`}>
        <TransitionGroup>
          {tokensWithCoinData.map((token) => (
            <CSSTransition
              key={token.mint}
              timeout={250}
              classNames="fade"
              in={userSettings ? userSettings.animationsEnabled !== false : true}
              nodeRef={nodeRef}
            >
              <div ref={nodeRef} className="token-account">
                <div className={`top-row ${activeModuleCount === 4 ? 'modules-4' : activeModuleCount === 5 ? 'modules-5' : ''}`}>
                  <div className="token-image-container">
                    {token.rug_ratio === 1 ? (
                      <RuggedIcon size={50} />
                    ) : (
                      token.img && (
                        <div className="image-wrapper">
                          {token.network === 1 && <RaydiumIcon size={20} className="network-icon" title="Raydium Token" />}
                          {token.network === 2 && <PFunIcon size={16} className="network-icon" title="Pump.Fun Token" />}
                          <img
                            title={token.name}
                            key={token.img}
                            src={token.img}
                            alt={token.name || token.mint}
                            className="token-image"
                            style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px', alignSelf: 'center' }}
                          />
                        </div>
                      )
                    )}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                      <span title={token.name} style={{ fontWeight: 'bold' }}>
                        {token.name ? `${token.name.slice(0, activeModuleCount >= 4 ? 10 : activeModuleCount <= 3 ? 20 : 14)} ` : ''}
                      </span>
                      <span>
                        &nbsp;{token.ticker && activeModuleCount < 4 ? `(${token.ticker.startsWith('$') ? token.ticker.slice(0, activeModuleCount === 4 ? 10 : activeModuleCount === 5 ? 8 : undefined) : `$${token.ticker.slice(0, activeModuleCount === 4 ? 9 : activeModuleCount === 5 ? 8 : undefined)}`}) ` : ''}&nbsp;
                      </span>
                      {token.rug_ratio === 1 && (
                        <RuggedIcon size={15} title="Creator Rugged other Coins!" />
                      )}
                      {token.top_10_holder_rate != null && token.top_10_holder_rate !== undefined && token.top_10_holder_rate !== 100 && token.top_10_holder_rate !== 0 && (
                        token.top_10_holder_rate > 20 ? (
                          <HolderIcon size={15} title={`Top 10 holders control ${token.top_10_holder_rate.toFixed(2)}% of the tokens!`} fill="red" />
                        ) : token.top_10_holder_rate > 10 ? (
                          <HolderIcon size={15} title={`Top 10 holders control ${token.top_10_holder_rate.toFixed(2)}% of the tokens!`} fill="orange" />
                        ) : (
                          <HolderIcon size={15} title={`Top 10 holders control ${token.top_10_holder_rate.toFixed(2)}% of the tokens!`} fill="green" />
                        )
                      )}
                      {token.renounced_mint === 0 && (
                        <MintIcon size={15} title="Mint Authority NOT renounced!" />
                      )}
                      {token.renounced_mint === 1 && (
                        <MintIcon size={15} title="Mint Authority renounced!" fill="green" />
                      )}
                      {token.renounced_freeze_account === 0 && (
                        <FreezIcon size={15} title="Freeze Authority NOT Revoked!" />
                      )}
                      {token.renounced_freeze_account === 1 && (
                        <FreezIcon size={15} title="Freeze Authority Revoke!" fill="green" />
                      )}
                      {(token.burn_status === "none" || token.burn_status === "") && (
                        <BurnedIcon size={15} title="LP token NOT burned!" fill="red" />
                      )}
                      {token.burn_status === "burn" && (
                        <BurnedIcon size={15} title="LP token burned!" fill="green" />
                      )}
                      {token.liquidity != null && token.liquidity !== undefined && (
                        token.liquidity < 5000 ? (
                          <LiquidityIcon size={15} title={`Very low liquidity $${Math.floor(token.liquidity)}`} fill="red" />
                        ) : token.liquidity < 10000 ? (
                          <LiquidityIcon size={15} title={`Low liquidity $${Math.floor(token.liquidity)}`} fill="orange" />
                        ) : token.liquidity > 50000 ? (
                          <LiquidityIcon size={15} title={`Good liquidity $${Math.floor(token.liquidity)}`} fill="green" />
                        ) : (
                          <LiquidityIcon size={15} title={`Moderate liquidity $${Math.floor(token.liquidity)}`} fill="lightgreen"/>
                        )
                      )}
                    </div>
                    <div className="mintStr" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span
                        title={`Click to copy address: ${token.mint}`}
                        onClick={() => handleCopy(token)}
                        style={{ cursor: 'pointer', fontSize: '14px', paddingLeft: '0rem', marginRight: activeModuleCount === 5 ? '0.5rem' : '1rem' }}
                        className={`mint-address ${copiedTokens[token.mint] ? 'copied' : ''}`}
                      >
                        {token.mint ? `${token.mint.slice(0, 4)}...${token.mint.slice(-4)}` : ''}
                      </span>
                      <span
                            title={`Click to copy Tracked Account address: ${token.trackedWalletAccount}`}
                            onClick={() => handleCopy2(token)}
                            style={{ cursor: 'pointer', fontSize: '14px', paddingLeft: '0rem', marginRight: activeModuleCount === 5 ? '0.5rem' : '1rem' }}
                            className={`mint-address ${copiedTokens[token.trackedWalletAccount] ? 'copied' : ''}`}
                          >Tracked Account: &nbsp; 
                          {token.trackedWalletAccount ? `${token.trackedWalletAccount.slice(0, 4)}...${token.trackedWalletAccount.slice(-4)}` : ''}
                        </span>
                      <div className="social-links">
                        {token.twitter && (
                          <a href={token.twitter} target="_blank" rel="noopener noreferrer" className={`token-link ${theme}`} title={`Visit token twitter: ${token.twitter}`}>
                            <svg className="social-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <path fill="currentColor" d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" />
                            </svg>
                          </a>
                        )}
                        {token.telegram && (
                          <a href={token.telegram} target="_blank" rel="noopener noreferrer" className={`token-link ${theme}`} title={`Visit token telegram: ${token.telegram}`}>
                            <svg className="social-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm121.8 169.9l-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z" />
                            </svg>
                          </a>
                        )}
                        {token.website && (
                          <a href={token.website} target="_blank" rel="noopener noreferrer" className={`token-link ${theme}`} title={`Visit token website: ${token.website}`}>
                            <svg className="social-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <path fill="currentColor" d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z" />
                            </svg>
                          </a>
                        )}
                        <a href={token.network === 1 ? `https://dexscreener.com/solana/${token.mint}` : `https://pump.fun/${token.mint}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`token-link ${theme}`}
                          title={token.network === 1 ? "View token on DexScreener" : "View token on Pump.Fun"}>
                          <svg className="social-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M432 320H400a16 16 0 0 0 -16 16V448H64V128H208a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16H48A48 48 0 0 0 0 112V464a48 48 0 0 0 48 48H400a48 48 0 0 0 48-48V336A16 16 0 0 0 432 320zM488 0h-128c-21.4 0-32.1 25.9-17 41l35.7 35.7L135 320.4a24 24 0 0 0 0 34L157.7 377a24 24 0 0 0 34 0L435.3 133.3 471 169c15 15 41 4.5 41-17V24A24 24 0 0 0 488 0z" />
                          </svg>
                        </a>
                        <a className={`token-link ${theme}`} href="#!" onClick={() => handleIconClick(token)} title={`Refresh Coin Data & Rug Check`}>
                          <svg className={`social-icons ${rotatingTokens[token.mint] ? 'rotate-animation' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path fill="currentColor" d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8z"/>
                          </svg>
                        </a>
                        <a href="#!" onClick={() => onPin(token)} className={`token-link ${theme}`} title="Pin this token">
                          <svg className="social-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M32 32C32 14.3 46.3 0 64 0H320c17.7 0 32 14.3 32 32s-14.3 32-32 32H290.5l11.4 148.2c36.7 19.9 65.7 53.2 79.5 94.7l1 3c3.3 9.8 1.6 20.5-4.4 28.8s-15.7 13.3-26 13.3H32c-10.3 0-19.9-4.9-26-13.3s-7.7-19.1-4.4-28.8l1-3c13.8-41.5 42.8-74.8 79.5-94.7L93.5 64H64C46.3 64 32 49.7 32 32zM160 384h64v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V384z"/>
                          </svg>
                        </a>
                        <button onClick={() => handleRemove(token)} className="unpin-button notMob" title="Remove Token">x</button>
                      </div>
                    </div>
                  </div>
                  <Snackbar
                    open={snackbarOpen}// Check that the token matches
                    autoHideDuration={snackbarSeverity !== 'info' ? 2000 : null} // Handle sending status
                    onClose={() => setSnackbarOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  >
                    <Alert
                      onClose={() => setSnackbarOpen(false)}
                      severity={snackbarSeverity}
                      iconMapping={{
                        success: <CheckCircleOutline fontSize="inherit" style={{ color: 'white' }} />,
                        error: <ErrorOutline fontSize="inherit" style={{ color: 'white' }} />,
                        info: <InfoOutlined fontSize="inherit" style={{ color: 'white' }} />,
                      }}
                      sx={{
                        backgroundColor:
                          snackbarSeverity === 'success'
                            ? 'green'
                            : snackbarSeverity === 'error'
                            ? 'red'
                            : 'orange',
                        color: '#fff',
                      }}
                    >
                      {snackbarMessage}
                    </Alert>
                  </Snackbar>
                </div>
                <div className={`button-group ${activeModuleCount < wideClassThreshold ? 'button-wide' : ''}`}>
                  {(userSettings?.quickBuy?.buyAmounts || [25, 10, 5, 1]).map((amount, idx) => (
                    <button title={`Buy for ${amount} ${userSettings?.expert?.currency || 'SOL'}`} key={idx} onClick={() => handleBuy(token, amount)}>{amount} {userSettings?.expert?.currency || 'SOL'}</button>
                  ))}
                </div>
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
        <Modal
          isOpen={activeModal === 'settings'}
          onRequestClose={closeModal}
          contentLabel="Settings Modal"
          overlayClassName="customOverlay"
          className="customContent"
          style={{ overlay: { top: '0px'}, content: { top: '50px' } }}
        >
          <SettingsForm theme={theme} onClose={closeModal} />
        </Modal>
      </div>
    </>
  );
};

export default Tracker;

import React, { createContext, useContext, useState, useEffect } from 'react';
import supabase from '../services/supabase';
import UserSettingsContext from './UserSettingsContext';
const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const { userSettings, userData, userId } = useContext(UserSettingsContext);
  const [walletData, setWalletData] = useState([]);
  const [solBalance, setSolBalance] = useState(0);
  const [coinDetails, setCoinDetails] = useState({});
  const [isRotated, setIsRotated] = useState(false);
  
  const fetchWalletData = async () => {
   // console.log("fetch wallet Data");
    setIsRotated(true);
    try {
      const { data: { session } } = await supabase.auth.getSession();
      const jwtToken = session?.access_token;
      const urlSol = `https://rcp1.pounce.bot/`;
      const fetchSolBalance = await fetch(urlSol, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        }
      });
      const { balance, tokens } = await fetchSolBalance.json();
      setSolBalance(balance / 1e9); // Convert lamports to SOL
      setWalletData([...tokens]);
     // console.log("tokens",tokens);
      const url = `https://rcp5.pounce.bot/`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify({ uId: userData.uId })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const tokenDetails = await response.json();
     // console.log("tokenDetails",tokenDetails);
       // Filter tokens where value > 0
    const filteredTokens = tokenDetails.filter(token => token.value > 0);
    //console.log("filteredTokens",filteredTokens);
 // Initialize an empty object to hold combined details
 const details = {};

 // Iterate over the walletData (tokens) and merge with filteredTokens
 tokens.forEach(token => {
   const mint = token.account.data.parsed.info.mint;

   // Find the corresponding filtered token
   const filteredToken = filteredTokens.find(ft => ft.mint === mint);

   if (filteredToken) {
     // Merge the token data with the filtered token data
     details[mint] = {
       ...token,
       ...filteredToken
     };
   } else {
     // If no matching filtered token, use the token as is
     details[mint] = { ...token };
   }
 });

 // Set the combined details to coinDetails state
 setCoinDetails(details);
      
    } catch (error) {
      console.error('Error fetching wallet data. Please try again later.');
    } finally {
        setTimeout(() => setIsRotated(false), 1000); // Stop rotation after animation ends
    }
  };

  const fetchCoinDetails = async (tokens) => {
    console.log("tokens",tokens);
    const details = {};

    // Filter out tokens with a zero balance
    const nonZeroTokens = tokens.filter(token => token.account.data.parsed.info.tokenAmount.uiAmount > 0);
    console.log("find nonZeroTokens",nonZeroTokens);
    const uId = nonZeroTokens.length > 0 ? nonZeroTokens[0].account.data.parsed.info.owner : null;
    console.log("uId",uId);
    for (const token of nonZeroTokens) {
      const mint = token.account.data.parsed.info.mint;
      const uId = token.account.data.parsed.info.owner;
      console.log("uId2",uId);
      // Skip fetching additional data for SOL, WSOL, and USDC
      if (mint === "So11111111111111111111111111111111111111112" || 
          mint === "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E" || 
          mint === "Es9vMFrzaCERk9Qmhipts7KohugqJUkUxePKuA8asf7z") {
            details[mint] = { ...token }; // Ensure these tokens are also stored
            continue;
      }

      const coinData = await fetchCoin(uId);
      const tokenDetails = coinData.reduce((acc, token) => {
        acc[token.mint] = token; // Map each token to its mint
        return acc;
      }, {});
      if (coinData) {
        details[mint] = { ...token, ...tokenDetails }; // Combine token data with fetched data
      } else {
        details[mint] = { ...token };
      }
    }
    console.log("details before setCoinDetails", details);
    setCoinDetails(details);
  };

  const fetchCoin = async (uId) => {
    const jwtToken = (await supabase.auth.getSession()).data.session?.access_token;
    console.log("uId3",uId);
    try {
      const url = `https://rcp5.pounce.bot/`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify({ uId: uId })
      });

      const responseText = await response.text();
      //console.log("coin data response:", responseText);
      if (response.ok) {
        const data = JSON.parse(responseText);
        return data;
      } else {
        console.error('Error fetching coin data:', response.statusText);
        return null;
      }
    } catch (error) {
      console.error('Error fetching coin data:', error.message);
      return null;
    }
  };

  return (
    <WalletContext.Provider value={{ walletData, setWalletData, solBalance, coinDetails, fetchWalletData, fetchCoinDetails, fetchCoin }}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);

import React from 'react';

const PFunIcon = ({ style, size = 16, title = "Token is in Pump.fun network!", fill = "currentColor" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill={fill}
        style={style}
        aria-label={title} // Improve accessibility with aria-label
    >
        <title>{title}</title>
        <g clipPath="url(#clip0_8532_4982)">
            <rect
                x="10.5"
                y="-1.5"
                width="9.296"
                height="16.971"
                rx="4.648"
                transform="rotate(45 10.5 -1.5)"
                fill="#fff"
            />
            <path
                d="M4.703 4.297L1.786 7.213a4.648 4.648 0 006.574 6.574l2.916-2.917-6.573-6.573z"
                fill="#52D48F"
            />
            <path
                d="M7.865 13.291A3.948 3.948 0 112.28 7.708l5.427-5.426a3.948 3.948 0 115.583 5.583L7.865 13.29zm5.921-11.504a4.648 4.648 0 00-6.573 0L1.787 7.213a4.648 4.648 0 106.573 6.574l5.427-5.427a4.648 4.648 0 000-6.573z"
                fill="#044735"
            />
        </g>
        <defs>
            <clipPath id="clip0_8532_4982">
                <rect width="16" height="16" fill="#fff" />
            </clipPath>
        </defs>
    </svg>
);

export default PFunIcon;

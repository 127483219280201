import React, { useState, useEffect, useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import '../../assets/styles/SettingsForm.css';
import UserSettingsContext from '../../hooks/UserSettingsContext';
import SettingsTabContext from '../../hooks/SettingsTabContext';
import supabase from '../../services/supabase';

const SettingsForm = ({ theme, onClose, refreshUserData  }) => {
  const { userSettings, setUserSettings, setIsNew, fetchUserDataWithRetry, userId, setUserId, userData } = useContext(UserSettingsContext);
  const { activeTab, setActiveTab } = useContext(SettingsTabContext);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const [settings, setSettings] = useState(userSettings || {
    quickBuy: {
      slippageDecimal: 50.0,
      MEV_PROTECTION: 'Fast',
      priorityFee: 'dynamic', 
      customPriorityFee: 0.0012,
      briberyAmount: 'dynamic', 
      customBriberyAmount: 0.0012,
      buyAmounts: [25, 10, 5, 1]
    },
    quickSell: {
      slippageDecimal: 50.0,
      MEV_PROTECTION: 'Fast',
      priorityFee: 'dynamic', 
      customPriorityFee: 0.0012,
      briberyAmount: 'dynamic', 
      customBriberyAmount: 0.0012,
      sellAmounts: [100, 75, 50, 25]
    },
    autoBuy: {
      maxTokenLimit: 0,
      slippageDecimal: 50.0,
      MEV_PROTECTION: 'Fast',
      priorityFee: 'dynamic', 
      customPriorityFee: 0.0012,
      briberyAmount: 'dynamic',
      customBriberyAmount: 0.0012,
      buyAmount: 1,
      autoBuyMode: {
        raydium: false,
        pumpFun: false
      }
    },
    copyTrade: {
      maxTokenLimit: 0,
      slippageDecimal: 50.0,
      MEV_PROTECTION: 'Fast',
      priorityFee: 'dynamic', 
      customPriorityFee: 0.0012,
      briberyAmount: 'dynamic', 
      customBriberyAmount: 0.0012,
      takeProfit: [],
      buyAmount: 'custom', 
      customBuyAmount: 100,
      sellAmount: 'custom',
      customSellAmount: 100,
      copyTradeMode: '',
    },
    filter: {
      raydiumFilters: {
        liquidityPoolSize: '',
        liquidityPoolSizeMax: '',
        devWalletMaxHolding: '',
        socialMediaProfiles: '',
        liquidityTokenBurned: false,
        mintAuthorityRevoked: false,
        copyToken: false,
      },
      pumpFunFilters: {
        devWalletMaxHolding: '',
        socialMediaProfiles: '',
        onlyPumpOriginal: false,
        copyToken: false,
      }
    },
    expert: {
      currency: 'SOL'
    },
    walletTracker: {
      wallets: []
    }
  });

  useEffect(() => {
    if (activeTab) {
      setActiveTab(activeTab);
    }
  }, [activeTab, setActiveTab]);

  useEffect(() => {
    localStorage.setItem('wallets', JSON.stringify(settings.walletTracker.wallets));
    //console.log("wallets",settings.walletTracker.wallets);
  }, [settings.walletTracker.wallets]);
 
  const handleQuickBuyPriorityClick = (value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      quickBuy: {
        ...prevSettings.quickBuy,
        priorityFee: value === 'custom' ? '' : value,
        customPriorityFee: value === 'custom' ? prevSettings.quickBuy.customPriorityFee : '',
      },
    }));
  };
  
  const handleQuickBuyBriberyClick = (value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      quickBuy: {
        ...prevSettings.quickBuy,
        briberyAmount: value === 'custom' ? '' : value,
        customBriberyAmount: value === 'custom' ? prevSettings.quickBuy.customBriberyAmount : '',
      },
    }));
  };
  
  const handleQuickBuyPriorityChange = (e) => {
    const value = e.target.value;
    setSettings((prevSettings) => ({
      ...prevSettings,
      quickBuy: {
        ...prevSettings.quickBuy,
        priorityFee: 'custom',
        customPriorityFee: value,
      },
    }));
  };
  
  const handleQuickBuyBriberyChange = (e) => {
    const value = e.target.value;
    setSettings((prevSettings) => ({
      ...prevSettings,
      quickBuy: {
        ...prevSettings.quickBuy,
        briberyAmount: 'custom',
        customBriberyAmount: value,
      },
    }));
  };

  const handleQuickSellPriorityClick = (value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      quickSell: {
        ...prevSettings.quickSell,
        priorityFee: value === 'custom' ? '' : value,
        customPriorityFee: value === 'custom' ? prevSettings.quickSell.customPriorityFee : '',
      },
    }));
  };
  
  const handleQuickSellBriberyClick = (value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      quickSell: {
        ...prevSettings.quickSell,
        briberyAmount: value === 'custom' ? '' : value,
        customBriberyAmount: value === 'custom' ? prevSettings.quickSell.customBriberyAmount : '',
      },
    }));
  };
  
  const handleQuickSellPriorityChange = (e) => {
    const value = e.target.value;
    setSettings((prevSettings) => ({
      ...prevSettings,
      quickSell: {
        ...prevSettings.quickSell,
        priorityFee: 'custom',
        customPriorityFee: value,
      },
    }));
  };
  
  const handleQuickSellBriberyChange = (e) => {
    const value = e.target.value;
    setSettings((prevSettings) => ({
      ...prevSettings,
      quickSell: {
        ...prevSettings.quickSell,
        briberyAmount: 'custom',
        customBriberyAmount: value,
      },
    }));
  };
  
  const handleAutoBuyPriorityClick = (value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      autoBuy: {
        ...prevSettings.autoBuy,
        priorityFee: value === 'custom' ? '' : value,
        customPriorityFee: value === 'custom' ? prevSettings.autoBuy.customPriorityFee : '',
      },
    }));
  };
  
  const handleAutoBuyBriberyClick = (value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      autoBuy: {
        ...prevSettings.autoBuy,
        briberyAmount: value === 'custom' ? '' : value,
        customBriberyAmount: value === 'custom' ? prevSettings.autoBuy.customBriberyAmount : '',
      },
    }));
  };
  
  const handleAutoBuyPriorityChange = (e) => {
    const value = e.target.value;
    setSettings((prevSettings) => ({
      ...prevSettings,
      autoBuy: {
        ...prevSettings.autoBuy,
        priorityFee: 'custom',
        customPriorityFee: value,
      },
    }));
  };
  
  const handleAutoBuyBriberyChange = (e) => {
    const value = e.target.value;
    setSettings((prevSettings) => ({
      ...prevSettings,
      autoBuy: {
        ...prevSettings.autoBuy,
        briberyAmount: 'custom',
        customBriberyAmount: value,
      },
    }));
  };

  const handleCopyTradePriorityClick = (value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      copyTrade: {
        ...prevSettings.copyTrade,
        priorityFee: value === 'custom' ? '' : value,
        customPriorityFee: value === 'custom' ? prevSettings.copyTrade.customPriorityFee : '',
      },
    }));
  };
  
  const handleCopyTradeBriberyClick = (value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      copyTrade: {
        ...prevSettings.copyTrade,
        briberyAmount: value === 'custom' ? '' : value,
        customBriberyAmount: value === 'custom' ? prevSettings.copyTrade.customBriberyAmount : '',
      },
    }));
  };
  
  const handleCopyTradePriorityChange = (e) => {
    const value = e.target.value;
    setSettings((prevSettings) => ({
      ...prevSettings,
      copyTrade: {
        ...prevSettings.copyTrade,
        priorityFee: 'custom',
        customPriorityFee: value,
      },
    }));
  };
  
  const handleCopyTradeBriberyChange = (e) => {
    const value = e.target.value;
    setSettings((prevSettings) => ({
      ...prevSettings,
      copyTrade: {
        ...prevSettings.copyTrade,
        briberyAmount: 'custom',
        customBriberyAmount: value,
      },
    }));
  };

  const handleCopyTradeBuyAmountClick = (value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      copyTrade: {
        ...prevSettings.copyTrade,
        buyAmount: value === 'custom' ? '' : value,
        customBuyAmount: value === 'custom' ? prevSettings.copyTrade.customBuyAmount : '',
      },
    }));
  };
  
  const handleCopyTradeSellAmountClick = (value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      copyTrade: {
        ...prevSettings.copyTrade,
        sellAmount: value === 'custom' ? '' : value,
        customSellAmount: value === 'custom' ? prevSettings.copyTrade.customSellAmount : '',
      },
    }));
  };
  
  const handleCopyTradeBuyAmountChange = (e) => {
    const value = e.target.value;
    setSettings((prevSettings) => ({
      ...prevSettings,
      copyTrade: {
        ...prevSettings.copyTrade,
        buyAmount: 'custom',
        customBuyAmount: value,
      },
    }));
  };
  
  const handleCopyTradeSellAmountChange = (e) => {
    const value = e.target.value;
    setSettings((prevSettings) => ({
      ...prevSettings,
      copyTrade: {
        ...prevSettings.copyTrade,
        sellAmount: 'custom',
        customSellAmount: value,
      },
    }));
  };

  const updateTop3Setting = (updatedSettings, copyTradeMode) => {
    let top3Value = false;
  
    switch (copyTradeMode) {
      case 'buySellAll':
      case 'buyAll':
      case 'buySellTop3':
      case 'buyTop3':
        top3Value = true;
        break;
      case 'buySellOwn':
      case 'buyOwn':
        top3Value = false;
        break;
      default:
        top3Value = false;
    }
  
    setSettings({
      ...updatedSettings,
      walletTracker: {
        ...updatedSettings.walletTracker,
        Top3: top3Value,
      },
    });
  };  
  
  const saveSettings = async () => {
    let currentUserId = userId;
  
    if (!currentUserId) {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        localStorage.setItem('userId', user.id);
        setUserId(user.id); 
        currentUserId = user.id;
      }
    }
    const { data: { session } } = await supabase.auth.getSession();
    const jwtToken = session?.access_token;
    const uniqueWallets = Array.from(new Set(settings.walletTracker.wallets.filter(wallet => wallet !== '')));
  
    const updatedSettings = {
      ...settings,
      walletTracker: {
        ...settings.walletTracker,
        wallets: uniqueWallets,
      }
    };
    //console.log("updatedSettings", updatedSettings);
    //console.log("userId", userId);
    //console.log("currentUserId", currentUserId);

    // Include top-level properties from userData
  const payload = {
    ...userData, // Spread in userData to include top-level properties
    settings: updatedSettings, // Override settings with updatedSettings
  };
  //console.log("payload", payload);
    try {
      const response = await fetch(`https://userdata.pounce.bot/?userId=${currentUserId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify(payload)
      });
  
      if (response.ok) {
        setUserSettings(updatedSettings); // Update context with new settings
        fetchUserDataWithRetry(); // Refresh user data
        setSnackbar({ open: true, message: 'Settings saved successfully!', severity: 'success' });
        setTimeout(onClose, 1500); // Close the settings modal after 2 seconds
      } else {
        throw new Error('Failed to update settings');
      }
    } catch (error) {
      console.error("Error updating settings:", error.message);
      setSnackbar({ open: true, message: 'Error saving settings. Please try again.', severity: 'error' });
    }
  };  
  /*
  const handleChange = (e, tab, field) => {
    setSettings({
      ...settings,
      [tab]: {
        ...settings[tab],
        [field]: e.target.value
      }
    });
  };*/
  const handleChange = (e, tab, field) => {
    const newValue = e.target.value;
    setSettings((prevSettings) => {
      const updatedSettings = {
        ...prevSettings,
        [tab]: {
          ...prevSettings[tab],
          [field]: newValue,
        },
      };
  
      if (tab === 'copyTrade' && field === 'copyTradeMode') {
        updateTop3Setting(updatedSettings, newValue);
      }
  
      return updatedSettings;
    });
  };
  

  const handleArrayChange = (e, tab, field, index) => {
    const newArray = [...settings[tab][field]];
    newArray[index] = e.target.value;
    setSettings({
      ...settings,
      [tab]: {
        ...settings[tab],
        [field]: newArray
      }
    });
  };

  const handleNestedChange = (e, parentField, field) => {
    const parentFields = parentField.split('.');
    const newState = { ...settings };
    let currentField = newState;

    for (let i = 0; i < parentFields.length; i++) {
      currentField = currentField[parentFields[i]];
    }

    currentField[field] = e.target.value;
    setSettings(newState);
  };

  const handleCheckboxChange = (e, parentField, field) => {
    const parentFields = parentField.split('.');
    const newState = { ...settings };
    let currentField = newState;

    for (let i = 0; i < parentFields.length; i++) {
      currentField = currentField[parentFields[i]];
    }

    currentField[field] = e.target.checked;
    setSettings(newState);
  };

  const handleSingleCheckboxChange = (e, parentField, value) => {
    const parentFields = parentField.split('.');
    const newState = { ...settings };
    let currentField = newState;
  
    for (let i = 0; i < parentFields.length; i++) {
      currentField = currentField[parentFields[i]];
    }
  
    if (currentField.socialMediaProfiles === value) {
      // If the checkbox being unchecked is currently selected, clear the value
      currentField.socialMediaProfiles = '';
    } else {
      // Otherwise, set the value to the new selection
      currentField.socialMediaProfiles = value;
    }
  
    setSettings(newState);
  };
  

  const handleAutoBuyModeChange = (e, field) => {
    const newAutoBuyMode = {
      ...settings.autoBuy.autoBuyMode,
      [field]: e.target.checked
    };

    // Ensure raydium is checked when newCoins is checked
    if (field === 'newCoins' && e.target.checked && !newAutoBuyMode.pumpFun) {
      newAutoBuyMode.raydium = true;
    }

    setSettings({
      ...settings,
      autoBuy: {
        ...settings.autoBuy,
        autoBuyMode: newAutoBuyMode
      }
    });
  };

  const handleAutoBuyModeSpecificChange = (e, parentField, field) => {
    const newAutoBuyMode = {
      ...settings[parentField].autoBuyMode,
      [field]: e.target.checked
    };

    setSettings({
      ...settings,
      [parentField]: {
        ...settings[parentField],
        autoBuyMode: newAutoBuyMode
      }
    });
  };

  const handleWalletChange = (e, index) => {
    const newWallets = [...settings.walletTracker.wallets];
    newWallets[index] = e.target.value;
    setSettings((prevSettings) => ({
      ...prevSettings,
      walletTracker: {
        ...prevSettings.walletTracker,
        wallets: newWallets
      }
    }));
  };

  const addWalletRow = () => {
    if (settings.walletTracker.wallets.length < 10) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        walletTracker: {
          ...prevSettings.walletTracker,
          wallets: [...prevSettings.walletTracker.wallets, '']
        }
      }));
    }
  };

  const removeWalletRow = (index) => {
    const newWallets = settings.walletTracker.wallets.filter((_, i) => i !== index);
    setSettings((prevSettings) => ({
      ...prevSettings,
      walletTracker: {
        ...prevSettings.walletTracker,
        wallets: newWallets
      }
    }));
  };

  const handleAutoBuyMaxTokenLimitChange = (e) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      autoBuy: {
        ...prevSettings.autoBuy,
        maxTokenLimit: parseInt(e.target.value, 10)  // Ensure value is an integer
      }
    }));
  };
  
  const handleCopyTradeMaxTokenLimitChange = (e) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      copyTrade: {
        ...prevSettings.copyTrade,
        maxTokenLimit: parseInt(e.target.value, 10)  // Ensure value is an integer
      }
    }));
  };    

  const getLabelForValue = (value) => {
    const valueLabelMap = {
      0.0012: "low",
      0.006: "medium",
      0.012: "high",
    };
    return valueLabelMap[value] || '';
  };

  useEffect(() => {
    if (userSettings) {
      setSettings({
        ...userSettings,
        quickBuy: {
          ...userSettings.quickBuy,
          customPriorityFee: userSettings.quickBuy.priorityFee === 'custom' ? userSettings.quickBuy.customPriorityFee : '',
          customBriberyAmount: userSettings.quickBuy.briberyAmount === 'custom' ? userSettings.quickBuy.customBriberyAmount : '',
        },
        quickSell: {
          ...userSettings.quickSell,
          customPriorityFee: userSettings.quickSell.priorityFee === 'custom' ? userSettings.quickSell.customPriorityFee : '',
          customBriberyAmount: userSettings.quickSell.briberyAmount === 'custom' ? userSettings.quickSell.customBriberyAmount : '',
        },
        autoBuy: {
          ...userSettings.autoBuy,
          customPriorityFee: userSettings.autoBuy.priorityFee === 'custom' ? userSettings.autoBuy.customPriorityFee : '',
          customBriberyAmount: userSettings.autoBuy.briberyAmount === 'custom' ? userSettings.autoBuy.customBriberyAmount : '',
          maxTokenLimit: userSettings.autoBuy.maxTokenLimit || 0,
        },
        copyTrade: {
          ...userSettings.copyTrade,
          customPriorityFee: userSettings.copyTrade.priorityFee === 'custom' ? userSettings.copyTrade.customPriorityFee : '',
          customBriberyAmount: userSettings.copyTrade.briberyAmount === 'custom' ? userSettings.copyTrade.customBriberyAmount : '',
          customBuyAmount: userSettings.copyTrade.buyAmount === 'custom' ? userSettings.copyTrade.customBuyAmount : '',
          customSellAmount: userSettings.copyTrade.sellAmount === 'custom' ? userSettings.copyTrade.customSellAmount : '',
          maxTokenLimit: userSettings.copyTrade.maxTokenLimit || 0,
        },
        walletTracker: {
          wallets: JSON.parse(localStorage.getItem('wallets')) || userSettings.walletTracker.wallets || [],
          Top3: true,
        }
      });
    }
  }, [userSettings]);
  
  
  

  return (
    <div className={`settings-form ${theme}`}>
      <div className={`tabs ${theme}`}>
        <div className={`tab ${activeTab === 'quick-buy' ? 'active' : ''}`} onClick={() => setActiveTab('quick-buy')}>Quick Buy</div>
        <div className={`tab ${activeTab === 'quick-sell' ? 'active' : ''}`} onClick={() => setActiveTab('quick-sell')}>Quick Sell</div>
        <div className={`tab ${activeTab === 'auto-buy' ? 'active' : ''}`} onClick={() => setActiveTab('auto-buy')}>Auto Buy</div>
        <div className={`tab ${activeTab === 'filter' ? 'active' : ''}`} onClick={() => setActiveTab('filter')}>Filter</div>
        <div className={`tab ${activeTab === 'wallet-tracker' ? 'active' : ''}`} onClick={() => setActiveTab('wallet-tracker')}>Wallet Tracker</div>
        <div className={`tab ${activeTab === 'copyTrade' ? 'active' : ''}`} onClick={() => setActiveTab('copyTrade')}>Copy Trading</div>
        <div className={`tab ${activeTab === 'expert' ? 'active' : ''}`} onClick={() => setActiveTab('expert')}>Expert</div>
      </div>

      <div className={`settings-section ${activeTab === 'quick-buy' ? 'active' : ''}`}>
        <label className="input-group-label"><b>Slippage</b></label>
        <div className="input-group">
          <input type="number" value={settings.quickBuy.slippageDecimal} onChange={(e) => handleChange(e, 'quickBuy', 'slippageDecimal')} />
          <span>%</span>
        </div>

        <label className="input-group-label"><b>MEV Protection</b></label>
        <div className="input-group">
          <span className={`icon2 ${settings.quickBuy.MEV_PROTECTION === 'Fast' ? 'fast' : 'secure'}`}>Fast</span>
          <label className="switch">
            <input type="checkbox" checked={settings.quickBuy.MEV_PROTECTION === 'Secure'} onChange={(e) => handleChange({ target: { value: e.target.checked ? 'Secure' : 'Fast' } }, 'quickBuy', 'MEV_PROTECTION')} />
            <div className="slider round"></div>
          </label>
          <span className={`icon2 ${settings.quickBuy.MEV_PROTECTION === 'Secure' ? 'fast' : 'secure'}`}>Secure</span>
        </div>
       
        <div className="input-group-row">
        <label className="input-groupPB-label"><b>Priority Fee</b></label>
        <div className="input-groupPB">
          <div 
            className={`input-containerPB ${theme} ${settings.quickBuy.priorityFee === 'dynamic' ? 'activePB' : ''}`} 
            onClick={() => handleQuickBuyPriorityClick('dynamic')}
          >
            <div>Dynamic</div>
          </div>
          {[0.0012, 0.006, 0.012].map((value, index) => (
            <div 
              key={index} 
              className={`input-containerPB ${theme} ${settings.quickBuy.priorityFee === value ? 'activePB' : ''}`} 
              onClick={() => handleQuickBuyPriorityClick(value)}
            >
              <div>×{index + 1}</div>
              <div className="amountPB">{value} SOL</div>
              <div className={`timePB ${theme}`}>≈ {getLabelForValue(value)}</div>
            </div>
          ))}
          <div className={`input-containerPB ${theme} ${settings.quickBuy.priorityFee === 'custom' ? 'activePB' : ''}`}>
            <input 
              type="number" 
              value={settings.quickBuy.customPriorityFee || ''} 
              onChange={handleQuickBuyPriorityChange} 
              placeholder="Custom" 
              className={`input-fieldPB ${theme}`}
              style={{ appearance: 'textfield' }}
            />
          </div>
          <span>SOL</span>
        </div>
      </div>

      <div className="input-group-row">
        <label className="input-groupPB-label"><b>Bribery Amount</b></label>
        <div className="input-groupPB">
          <div 
            className={`input-containerPB ${theme} ${settings.quickBuy.briberyAmount === 'dynamic' ? 'activePB' : ''}`} 
            onClick={() => handleQuickBuyBriberyClick('dynamic')}
          >
            <div>Dynamic</div>
          </div>
          {[0.0012, 0.006, 0.012].map((value, index) => (
            <div 
              key={index} 
              className={`input-containerPB ${theme} ${settings.quickBuy.briberyAmount === value ? 'activePB' : ''}`} 
              onClick={() => handleQuickBuyBriberyClick(value)}
            >
              <div>×{index + 1}</div>
              <div className="amountPB">{value} SOL</div>
              <div className={`timePB ${theme}`}>≈ {getLabelForValue(value)}</div>
            </div>
          ))}
          <div className={`input-containerPB ${theme} ${settings.quickBuy.briberyAmount === 'custom' ? 'activePB' : ''}`}>
            <input 
              type="number" 
              value={settings.quickBuy.customBriberyAmount || ''} 
              onChange={handleQuickBuyBriberyChange} 
              placeholder="Custom" 
              className={`input-fieldPB ${theme}`}
              style={{ appearance: 'textfield' }}
            />
          </div>
          <span>SOL</span>
        </div>
      </div>

        <label className="input-group-label"><b>Buy Amount Buttons</b></label>
        <div className="input-group">
          {settings.quickBuy.buyAmounts && settings.quickBuy.buyAmounts.map((amount, index) => (
            <div className="input-group-item" key={index}>
              <input type="number" value={amount} onChange={(e) => handleArrayChange(e, 'quickBuy', 'buyAmounts', index)} />
              <span>SOL</span>
            </div>
          ))}
        </div>
      </div>

      <div className={`settings-section ${activeTab === 'quick-sell' ? 'active' : ''}`}>
        <label className="input-group-label"><b>Slippage</b></label>
        <div className="input-group">
          <input type="number" value={settings.quickSell.slippageDecimal} onChange={(e) => handleChange(e, 'quickSell', 'slippageDecimal')} />
          <span>%</span>
        </div>

        <label className="input-group-label"><b>MEV Protection</b></label>
        <div className="input-group">
          <span className={`icon2 ${settings.quickSell.MEV_PROTECTION === 'Fast' ? 'fast' : 'secure'}`}>Fast</span>
          <label className="switch">
            <input type="checkbox" checked={settings.quickSell.MEV_PROTECTION === 'Secure'} onChange={(e) => handleChange({ target: { value: e.target.checked ? 'Secure' : 'Fast' } }, 'quickSell', 'MEV_PROTECTION')} />
            <div className="slider round"></div>
          </label>
          <span className={`icon2 ${settings.quickSell.MEV_PROTECTION === 'Secure' ? 'fast' : 'secure'}`}>Secure</span>
        </div>

        <div className="input-group-row">
        <label className="input-groupPB-label"><b>Priority Fee</b></label>
          <div className="input-groupPB">
            <div 
              className={`input-containerPB ${theme} ${settings.quickSell.priorityFee === 'dynamic' ? 'activePB' : ''}`} 
              onClick={() => handleQuickSellPriorityClick('dynamic')}
            >
              <div>Dynamic</div>
            </div>
            {[0.0012, 0.006, 0.012].map((value, index) => (
              <div 
                key={index} 
                className={`input-containerPB ${theme} ${settings.quickSell.priorityFee === value ? 'activePB' : ''}`} 
                onClick={() => handleQuickSellPriorityClick(value)}
              >
                <div>×{index + 1}</div>
                <div className="amountPB">{value} SOL</div>
                <div className={`timePB ${theme}`}>≈ {getLabelForValue(value)}</div>
              </div>
            ))}
            <div className={`input-containerPB ${theme} ${settings.quickSell.priorityFee === 'custom' ? 'activePB' : ''}`}>
              <input 
                type="number" 
                value={settings.quickSell.customPriorityFee || ''} 
                onChange={handleQuickSellPriorityChange} 
                placeholder="Custom" 
                className={`input-fieldPB ${theme}`}
                style={{ appearance: 'textfield' }}
              />
            </div>
            <span>SOL</span>
          </div>
        </div>

        <div className="input-group-row">
          <label className="input-groupPB-label"><b>Bribery Amount</b></label>
          <div className="input-groupPB">
            <div 
              className={`input-containerPB ${theme} ${settings.quickSell.briberyAmount === 'dynamic' ? 'activePB' : ''}`} 
              onClick={() => handleQuickSellBriberyClick('dynamic')}
            >
              <div>Dynamic</div>
            </div>
            {[0.0012, 0.006, 0.012].map((value, index) => (
              <div 
                key={index} 
                className={`input-containerPB ${theme} ${settings.quickSell.briberyAmount === value ? 'activePB' : ''}`} 
                onClick={() => handleQuickSellBriberyClick(value)}
              >
                <div>×{index + 1}</div>
                <div className="amountPB">{value} SOL</div>
                <div className={`timePB ${theme}`}>≈ {getLabelForValue(value)}</div>
              </div>
            ))}
            <div className={`input-containerPB ${theme} ${settings.quickSell.briberyAmount === 'custom' ? 'activePB' : ''}`}>
              <input 
                type="number" 
                value={settings.quickSell.customBriberyAmount || ''} 
                onChange={handleQuickSellBriberyChange} 
                placeholder="Custom" 
                className={`input-fieldPB ${theme}`}
                style={{ appearance: 'textfield' }}
              />
            </div>
            <span>SOL</span>
          </div>
        </div>

        <label className="input-group-label"><b>Sell Amounts</b></label>
        <div className="input-group">
          {settings.quickSell.sellAmounts && settings.quickSell.sellAmounts.map((amount, index) => (
            <div className="input-group-item" key={index}>
              <input type="number" value={amount} onChange={(e) => handleArrayChange(e, 'quickSell', 'sellAmounts', index)} />
              <span>%</span>
            </div>
          ))}
        </div>
      </div>

      <div className={`settings-section ${activeTab === 'auto-buy' ? 'active' : ''}`}>
        <label className="input-group-label"><b>Auto Buy Mode</b></label>
        {/*
        <div className="checkbox-group">
          <input type="checkbox" checked={settings.autoBuy.autoBuyMode.newCoins} onChange={(e) => handleAutoBuyModeChange(e, 'newCoins')} />
          <span>New Coins</span>
        </div>
        {settings.autoBuy.autoBuyMode.newCoins && (
          <div className="input-group">
            <div className="checkbox-group">
              <input type="checkbox" style={{ width: '10px' }} checked={settings.autoBuy.autoBuyMode.raydium} onChange={(e) => handleAutoBuyModeSpecificChange(e, 'autoBuy', 'raydium')} />
              <span style={{ paddingRight: '15px' }}>Raydium</span>
            </div>
            <div className="checkbox-group">
              <input type="checkbox" style={{ width: '10px' }} checked={settings.autoBuy.autoBuyMode.pumpFun} onChange={(e) => handleAutoBuyModeSpecificChange(e, 'autoBuy', 'pumpFun')} />
              <span>Pump.fun</span>
            </div>
          </div>
        )}
        <div className="checkbox-group">
          <input type="checkbox" checked={settings.autoBuy.autoBuyMode.walletTracker} onChange={(e) => handleAutoBuyModeChange(e, 'walletTracker')} />
          <span>Wallet Tracker</span>
        </div>
*/}
        <div className="input-group">
            <div className="checkbox-group">
              <input type="checkbox" style={{ width: '10px' }} checked={settings.autoBuy.autoBuyMode.raydium} onChange={(e) => handleAutoBuyModeSpecificChange(e, 'autoBuy', 'raydium')} />
              <span style={{ paddingRight: '15px' }}>Raydium</span>
            </div>
            <div className="checkbox-group">
              <input type="checkbox" style={{ width: '10px' }} checked={settings.autoBuy.autoBuyMode.pumpFun} onChange={(e) => handleAutoBuyModeSpecificChange(e, 'autoBuy', 'pumpFun')} />
              <span>Pump.fun</span>
            </div>
          </div>
          <label className="input-group-label"><b>Max Token Limit</b></label>
          <div className="input-group">
            <select value={settings.autoBuy.maxTokenLimit} onChange={handleAutoBuyMaxTokenLimitChange}>
              <option value="0" title="No max token limit">Off</option>
              {Array.from({ length: 100 }, (_, i) => (
                <option key={i + 1} value={i + 1}>{i + 1}</option>
              ))}
            </select>
          </div>

        <label className="input-group-label"><b>Slippage</b></label>
        <div className="input-group">
          <input type="number" value={settings.autoBuy.slippageDecimal} onChange={(e) => handleChange(e, 'autoBuy', 'slippageDecimal')} />
          <span>%</span>
        </div>

        <label className="input-group-label"><b>MEV Protection</b></label>
        <div className="input-group">
          <span className={`icon2 ${settings.autoBuy.MEV_PROTECTION === 'Fast' ? 'fast' : 'secure'}`}>Fast</span>
          <label className="switch">
            <input type="checkbox" checked={settings.autoBuy.MEV_PROTECTION === 'Secure'} onChange={(e) => handleChange({ target: { value: e.target.checked ? 'Secure' : 'Fast' } }, 'autoBuy', 'MEV_PROTECTION')} />
            <div className="slider round"></div>
          </label>
          <span className={`icon2 ${settings.autoBuy.MEV_PROTECTION === 'Secure' ? 'fast' : 'secure'}`}>Secure</span>
        </div>

        <div className="input-group-row">
          <label className="input-groupPB-label"><b>Priority Fee</b></label>
          <div className="input-groupPB">
            <div 
              className={`input-containerPB ${theme} ${settings.autoBuy.priorityFee === 'dynamic' ? 'activePB' : ''}`} 
              onClick={() => handleAutoBuyPriorityClick('dynamic')}
            >
              <div>Dynamic</div>
            </div>
            {[0.0012, 0.006, 0.012].map((value, index) => (
              <div 
                key={index} 
                className={`input-containerPB ${theme} ${settings.autoBuy.priorityFee === value ? 'activePB' : ''}`} 
                onClick={() => handleAutoBuyPriorityClick(value)}
              >
                <div>×{index + 1}</div>
                <div className="amountPB">{value} SOL</div>
                <div className={`timePB ${theme}`}>≈ {getLabelForValue(value)}</div>
              </div>
            ))}
            <div className={`input-containerPB ${theme} ${settings.autoBuy.priorityFee === 'custom' ? 'activePB' : ''}`}>
              <input 
                type="number" 
                value={settings.autoBuy.customPriorityFee || ''} 
                onChange={handleAutoBuyPriorityChange} 
                placeholder="Custom" 
                className={`input-fieldPB ${theme}`}
                style={{ appearance: 'textfield' }}
              />
            </div>
            <span>SOL</span>
          </div>
        </div>

        <div className="input-group-row">
          <label className="input-groupPB-label"><b>Bribery Amount</b></label>
          <div className="input-groupPB">
            <div 
              className={`input-containerPB ${theme} ${settings.autoBuy.briberyAmount === 'dynamic' ? 'activePB' : ''}`} 
              onClick={() => handleAutoBuyBriberyClick('dynamic')}
            >
              <div>Dynamic</div>
            </div>
            {[0.0012, 0.006, 0.012].map((value, index) => (
              <div 
                key={index} 
                className={`input-containerPB ${theme} ${settings.autoBuy.briberyAmount === value ? 'activePB' : ''}`} 
                onClick={() => handleAutoBuyBriberyClick(value)}
              >
                <div>×{index + 1}</div>
                <div className="amountPB">{value} SOL</div>
                <div className={`timePB ${theme}`}>≈ {getLabelForValue(value)}</div>
              </div>
            ))}
            <div className={`input-containerPB ${theme} ${settings.autoBuy.briberyAmount === 'custom' ? 'activePB' : ''}`}>
              <input 
                type="number" 
                value={settings.autoBuy.customBriberyAmount || ''} 
                onChange={handleAutoBuyBriberyChange} 
                placeholder="Custom" 
                className={`input-fieldPB ${theme}`}
                style={{ appearance: 'textfield' }}
              />
            </div>
            <span>SOL</span>
          </div>
        </div>
        <label className="input-group-label"><b>Buy Amount</b></label>
        <div className="input-group">
          <input type="number" value={settings.autoBuy.buyAmount} onChange={(e) => handleChange(e, 'autoBuy', 'buyAmount')} />
          <span>SOL</span>
        </div>
      </div>
            
      <div className={`settings-section ${activeTab === 'filter' ? 'active' : ''}`}>
        <h3>Filter for New Coins Listing & Auto-Buy</h3>
        <h4>Raydium</h4>
        <label className="input-group-label"><b>Min. Liquidity Pool Size</b></label>
        <div className="input-group">
          <input type="text" value={settings.filter.raydiumFilters.liquidityPoolSize} onChange={(e) => handleNestedChange(e, 'filter.raydiumFilters', 'liquidityPoolSize')} />
          <span>SOL</span>
        </div>
        <label className="input-group-label"><b>Max. Liquidity Pool Size</b></label>
        <div className="input-group">
          <input type="text" value={settings.filter.raydiumFilters.liquidityPoolSizeMax} onChange={(e) => handleNestedChange(e, 'filter.raydiumFilters', 'liquidityPoolSizeMax')} />
          <span>SOL</span>
        </div>
{/*
        <label className="input-group-label"><b>Dev Wallet Max Holding</b></label>
        <div className="input-group">
          <input type="number" value={settings.filter.raydiumFilters.devWalletMaxHolding} onChange={(e) => handleNestedChange(e, 'filter.raydiumFilters', 'devWalletMaxHolding')} />
          <span>%</span>
        </div>
*/}
        <label className="input-group-label"><b>Social Media Profiles</b></label>
        <div className="checkbox-group">
          <input type="checkbox" checked={settings.filter.raydiumFilters.socialMediaProfiles === 'minOne'} onChange={(e) => handleSingleCheckboxChange(e, 'filter.raydiumFilters', 'minOne')} />
          <span>Min One Social Media Profile</span>
        </div>
        <div className="checkbox-group">
          <input type="checkbox" checked={settings.filter.raydiumFilters.socialMediaProfiles === 'all'} onChange={(e) => handleSingleCheckboxChange(e, 'filter.raydiumFilters', 'all')} />
          <span>All Social Media Profiles (Telegram, X (Twitter), Website)</span>
        </div>

        <label className="input-group-label"><b>Rugcheck</b></label>
        <label className="checkbox-group">
          <input type="checkbox" checked={settings.filter.raydiumFilters.liquidityTokenBurned} onChange={(e) => handleCheckboxChange(e, 'filter.raydiumFilters', 'liquidityTokenBurned')} />
          Liquidity Token Burned
        </label>

        <label className="checkbox-group">
          <input type="checkbox" checked={settings.filter.raydiumFilters.mintAuthorityRevoked} onChange={(e) => handleCheckboxChange(e, 'filter.raydiumFilters', 'mintAuthorityRevoked')} />
          Mint Authority Revoked
        </label>

        <label className="checkbox-group">
          <input type="checkbox" checked={settings.filter.raydiumFilters.copyToken} onChange={(e) => handleCheckboxChange(e, 'filter.raydiumFilters', 'copyToken')} />
          Filter Duplicate Tokens (Last 50)
        </label>

        <h4>Pump.Fun</h4>
{/*        
        <label className="input-group-label"><b>Dev Wallet Max Holding</b></label>
        <div className="input-group">
          <input type="number" value={settings.filter.pumpFunFilters.devWalletMaxHolding} onChange={(e) => handleNestedChange(e, 'filter.pumpFunFilters', 'devWalletMaxHolding')} />
          <span>%</span>
        </div>
*/}
        <label className="input-group-label"><b>Social Media Profiles</b></label>
        <div className="checkbox-group">
          <input type="checkbox" checked={settings.filter.pumpFunFilters.socialMediaProfiles === 'minOne'} onChange={(e) => handleSingleCheckboxChange(e, 'filter.pumpFunFilters', 'minOne')} />
          <span>Min One Social Media Profile</span>
        </div>
        <div className="checkbox-group">
          <input type="checkbox" checked={settings.filter.pumpFunFilters.socialMediaProfiles === 'all'} onChange={(e) => handleSingleCheckboxChange(e, 'filter.pumpFunFilters', 'all')} />
          <span>All Social Media Profiles (Telegram, X (Twitter), Website)</span>
        </div>
        <div className="checkbox-group">
          <input type="checkbox" checked={settings.filter.pumpFunFilters.onlyPumpOriginal} onChange={(e) => handleCheckboxChange(e, 'filter.pumpFunFilters', 'onlyPumpOriginal')} />
          <span>Display only original pump.fun tokens based on their mint address</span>
        </div>
        <div className="checkbox-group">
          <input type="checkbox" checked={settings.filter.pumpFunFilters.copyToken} onChange={(e) => handleCheckboxChange(e, 'filter.pumpFunFilters', 'copyToken')} />
          <span>Filter Duplicate Tokens (Last 50)</span>
        </div>
      </div>

      <div className={`settings-section ${activeTab === 'wallet-tracker' ? 'active' : ''}`}>
        <label className="input-group-label"><b>Wallets</b></label>
        {(settings.walletTracker.wallets || []).map((wallet, index) => (
          <div className="input-group" key={index}>
            <input
              type="text"
              value={wallet}
              onChange={(e) => handleWalletChange(e, index)}
              style={{ width: '450px' }}
            />
            <button className="small-button" onClick={() => removeWalletRow(index)}>x</button>
          </div>
        ))}
        {settings.walletTracker.wallets.length < 10 && (
          <button className="small-button" onClick={addWalletRow}>Add Wallet</button>
        )}
      </div>

      <div className={`settings-section ${activeTab === 'copyTrade' ? 'active' : ''}`}>
      <label className="input-group-label"><b>Copy Trade Mode</b></label>
      <div className={`input-groupCopy ${theme}`}>
        <select value={settings.copyTrade.copyTradeMode} onChange={(e) => handleChange(e, 'copyTrade', 'copyTradeMode')} className="custom-selectCopy">
          <option value="" disabled >Please choose a Copy Trade Mode</option>
          <option value="buySellAll" title="Copy Buys & Sells from Top 3 Tracked Wallets and Your Own Tracked Wallets">Copy Buys & Sells (Top 3 & My Wallets)</option>
          <option value="buyAll" title="Copy Buys from Top 3 Tracked Wallets and Your Own Tracked Wallets">Copy Buys (Top 3 & My Wallets)</option>
          <option value="buySellTop3" title="Copy Buys & Sells from Top 3 Tracked Wallets">Copy Buys & Sells (Top 3 Wallets)</option>
          <option value="buyTop3" title="Copy Buys from Top 3 Tracked Wallets">Copy Buys (Top 3 Wallets)</option>
          <option value="buySellOwn" title="Copy Buys & Sells from Your Own Tracked Wallets">Copy Buys & Sells (My Wallets)</option>
          <option value="buyOwn" title="Copy Buys from Your Own Tracked Wallets">Copy Buys (My Wallets)</option>
        </select>
      </div>
      <label className="input-group-label"><b>Max Token Limit</b></label>
      <div className="input-group">
        <select value={settings.copyTrade.maxTokenLimit} onChange={handleCopyTradeMaxTokenLimitChange}>
          <option value="0" title="No max token limit">Off</option>
          {Array.from({ length: 100 }, (_, i) => (
            <option key={i + 1} value={i + 1}>{i + 1}</option>
          ))}
        </select>
      </div>

        <label className="input-group-label"><b>Slippage</b></label>
        <div className="input-group">
          <input type="number" value={settings.copyTrade.slippageDecimal} onChange={(e) => handleChange(e, 'copyTrade', 'slippageDecimal')} />
          <span>%</span>
        </div>

        <label className="input-group-label"><b>MEV Protection</b></label>
        <div className="input-group">
          <span className={`icon2 ${settings.copyTrade.MEV_PROTECTION === 'Fast' ? 'fast' : 'secure'}`}>Fast</span>
          <label className="switch">
            <input type="checkbox" checked={settings.copyTrade.MEV_PROTECTION === 'Secure'} onChange={(e) => handleChange({ target: { value: e.target.checked ? 'Secure' : 'Fast' } }, 'copyTrade', 'MEV_PROTECTION')} />
            <div className="slider round"></div>
          </label>
          <span className={`icon2 ${settings.copyTrade.MEV_PROTECTION === 'Secure' ? 'fast' : 'secure'}`}>Secure</span>
        </div>

        <div className="input-group-row">
          <label className="input-groupPB-label"><b>Priority Fee</b></label>
          <div className="input-groupPB">
            <div 
              className={`input-containerPB ${theme} ${settings.copyTrade.priorityFee === 'dynamic' ? 'activePB' : ''}`} 
              onClick={() => handleCopyTradePriorityClick('dynamic')}
            >
              <div>Dynamic</div>
            </div>
            {[0.0012, 0.006, 0.012].map((value, index) => (
              <div 
                key={index} 
                className={`input-containerPB ${theme} ${settings.copyTrade.priorityFee === value ? 'activePB' : ''}`} 
                onClick={() => handleCopyTradePriorityClick(value)}
              >
                <div>×{index + 1}</div>
                <div className="amountPB">{value} SOL</div>
                <div className={`timePB ${theme}`}>≈ {getLabelForValue(value)}</div>
              </div>
            ))}
            <div className={`input-containerPB ${theme} ${settings.copyTrade.priorityFee === 'custom' ? 'activePB' : ''}`}>
              <input 
                type="number" 
                value={settings.copyTrade.customPriorityFee || ''} 
                onChange={handleCopyTradePriorityChange} 
                placeholder="Custom" 
                className={`input-fieldPB ${theme}`}
                style={{ appearance: 'textfield' }}
              />
            </div>
            <span>SOL</span>
          </div>
        </div>

        <div className="input-group-row">
          <label className="input-groupPB-label"><b>Bribery Amount</b></label>
          <div className="input-groupPB">
            <div 
              className={`input-containerPB ${theme} ${settings.copyTrade.briberyAmount === 'dynamic' ? 'activePB' : ''}`} 
              onClick={() => handleCopyTradeBriberyClick('dynamic')}
            >
              <div>Dynamic</div>
            </div>
            {[0.0012, 0.006, 0.012].map((value, index) => (
              <div 
                key={index} 
                className={`input-containerPB ${theme} ${settings.copyTrade.briberyAmount === value ? 'activePB' : ''}`} 
                onClick={() => handleCopyTradeBriberyClick(value)}
              >
                <div>×{index + 1}</div>
                <div className="amountPB">{value} SOL</div>
                <div className={`timePB ${theme}`}>≈ {getLabelForValue(value)}</div>
              </div>
            ))}
            <div className={`input-containerPB ${theme} ${settings.copyTrade.briberyAmount === 'custom' ? 'activePB' : ''}`}>
              <input 
                type="number" 
                value={settings.copyTrade.customBriberyAmount || ''} 
                onChange={handleCopyTradeBriberyChange} 
                placeholder="Custom" 
                className={`input-fieldPB ${theme}`}
                style={{ appearance: 'textfield' }}
              />
            </div>
            <span>SOL</span>
          </div>
        </div>
        <div className="input-group-row">
        <label className="input-group-label"><b>Buy Amount</b></label>
        <div className="input-groupPB">
          {['100% of Transaction', '50% of Transaction', '5 SOL', '1 SOL'].map((value, index) => (
            <div 
              key={index} 
              className={`input-containerPB ${theme} ${settings.copyTrade.buyAmount === value ? 'activePB' : ''}`} 
              onClick={() => handleCopyTradeBuyAmountClick(value)}
            >
              <div>{value}</div>
            </div>
          ))}
          <div className={`input-containerPB ${theme} ${settings.copyTrade.buyAmount === 'custom' ? 'activePB' : ''}`}>
            <input 
              type="number" 
              value={settings.copyTrade.customBuyAmount || ''} 
              onFocus={() => handleCopyTradeBuyAmountClick('custom')}
              onChange={handleCopyTradeBuyAmountChange} 
              placeholder="Custom" 
              className={`input-fieldPB ${theme}`}
              style={{ appearance: 'textfield' }}
            />
          </div>
          <span>SOL</span>
        </div>
          </div>
          <div className="input-group-row"></div>
        <label className="input-group-label"><b>Sell Amount</b></label>
        <div className="input-groupPB">
          {['100% of Tokens', '75% of Tokens', '50% of Tokens', '25% of Tokens'].map((value, index) => (
            <div 
              key={index} 
              className={`input-containerPB ${theme} ${settings.copyTrade.sellAmount === value ? 'activePB' : ''}`} 
              onClick={() => handleCopyTradeSellAmountClick(value)}
            >
              <div>{value}</div>
            </div>
          ))}
          <div className={`input-containerPB ${theme} ${settings.copyTrade.sellAmount === 'custom' ? 'activePB' : ''}`}>
            <input 
              type="number" 
              value={settings.copyTrade.customSellAmount || ''} 
              onFocus={() => handleCopyTradeSellAmountClick('custom')}
              onChange={handleCopyTradeSellAmountChange} 
              placeholder="Custom" 
              className={`input-fieldPB ${theme}`}
              style={{ appearance: 'textfield' }}
            />
          </div>
          <span>Tokens</span>
        </div>
      </div>

      <div className={`settings-section ${activeTab === 'expert' ? 'active' : ''}`}>
        <label className="input-group-label"><b>Currency</b></label>
        <div className="input-group">
          <select value={settings.expert.currency} onChange={(e) => handleChange(e, 'expert', 'currency')}>
            <option value="SOL">SOL</option>
            <option value="USDC">USDC</option>
            <option value="WSOL">WSOL</option>
          </select>
        </div>
      </div>

      <div className="button-container">
        <button onClick={saveSettings}>Save Settings & Close</button>
        <button onClick={onClose}>Close</button>
      </div>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={snackbar.severity === 'success' ? 3000 : 3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SettingsForm;

import supabase from '../services/supabase';

const servers = [
    'https://be.pounce.bot/events',
    'https://be2.pounce.bot/events'
];

let eventSource = null;
const subscribers = [];
let isConnecting = false;
let connectionEstablished = false;
let retryTimeout = null;

const subscribe = (callback) => {
    subscribers.push(callback);
    // Return an unsubscribe function
    return () => {
        const index = subscribers.indexOf(callback);
        if (index !== -1) {
            subscribers.splice(index, 1);
        }
    };
};

const notifySubscribers = (token) => {
    subscribers.forEach(callback => callback(token));
};

const connectToServer = async (index) => {
    // Fetch the JWT token directly within this function
    let jwtToken = null;
    try {
        const { data: { session } } = await supabase.auth.getSession();
        jwtToken = session?.access_token || null;
        if (!jwtToken) {
            console.error('Unable to obtain JWT token');
            throw new Error('Unable to obtain JWT token');
        }
    } catch (error) {
        console.error('Error fetching JWT token:', error.message);
        try {
            // Retry fetching the JWT token
            const { data: { session } } = await supabase.auth.getSession();
            jwtToken = session?.access_token || null;
            if (!jwtToken) {
                console.error('Unable to obtain JWT token after retry');
                isConnecting = false;
                return;
            }
        } catch (retryError) {
            console.error('Retry error fetching JWT token:', retryError.message);
            isConnecting = false;
            return;
        }
    }

    if (eventSource) {
        eventSource.close();
    }

    const serverUrl = servers[index];
    eventSource = new EventSource(`${serverUrl}?token=${jwtToken}`);

    eventSource.onmessage = event => {
        const newToken = JSON.parse(event.data);
        if (newToken.message && newToken.message === 'Connected successfully') {
            console.log('Connected successfully to:', serverUrl);
            connectionEstablished = true; // Mark connection as established
            isConnecting = false;
            return;
        }
        if (!newToken.mint) {
            console.log('Invalid token data received, missing mint identifier');
            return;
        }
        notifySubscribers(newToken);
    };

    eventSource.onerror = () => {
        console.error('SSE connection error with server', serverUrl);
        eventSource.close();
        connectionEstablished = false; // Ensure connectionEstablished is reset
        isConnecting = false; // Reset isConnecting flag

        if (!connectionEstablished) {
            // Try next server
            const nextServerIndex = (index + 1) % servers.length;
           // console.log('Retrying with next server:', servers[nextServerIndex]);
            clearTimeout(retryTimeout); // Clear any existing timeout
            retryTimeout = setTimeout(() => connectToServer(nextServerIndex), 5000); // Retry after 5 seconds
        }
    };

    eventSource.onopen = () => {
      //  console.log('Connection opened with server:', serverUrl);
        isConnecting = false; // Reset isConnecting when connection is successfully opened
    };
};

const startSSEConnection = async () => {
    if (isConnecting || connectionEstablished) return; // Prevent multiple simultaneous connection attempts or reconnects once established

    isConnecting = true;

    const initialServerIndex = Math.floor(Math.random() * servers.length); // Choose a random initial server
   // console.log("Starting SSE connection");
    await connectToServer(initialServerIndex);
};

const stopSSEConnection = () => {
    if (eventSource) {
        eventSource.close();
    }
    isConnecting = false;
    connectionEstablished = false;
    clearTimeout(retryTimeout); // Clear any existing timeout
};

export { subscribe, startSSEConnection, stopSSEConnection };

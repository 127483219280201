import React from 'react';

const FreezIcon = ({ style, size = 50, title = "Freeze Authority NOT Revoked!!", fill = "red" }) => (
  <svg
    className="social-icons"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    title={title}
    style={{ fill, width: size, height: size, marginRight: '5px', alignSelf: 'center', ...style }}
  >
    <title>{title}</title>
    <path
      fill={fill}
      d="M349.4 44.6c5.9-13.7 1.5-29.7-10.6-38.5s-28.6-8-39.9 1.8l-256 224c-10 8.8-13.6 22.9-8.9 35.3S50.7 288 64 288l111.5 0L98.6 467.4c-5.9 13.7-1.5 29.7 10.6 38.5s28.6 8 39.9-1.8l256-224c10-8.8 13.6-22.9 8.9-35.3s-16.6-20.7-30-20.7l-111.5 0L349.4 44.6z"
    />
  </svg>
);

export default FreezIcon;

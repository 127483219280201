import React, { useState, useEffect } from 'react';
import supabase from '../services/supabase';

const MAX_CACHE_SIZE = 100;
const imgCache = new Map();
const MAX_RETRIES = 1;
const useBase64 = false;  // Set this to false to use object URLs instead of Base64
const IMAGE_LOAD_TIMEOUT = 5000;  // Timeout duration in milliseconds (e.g., 5000ms = 5 seconds)

const AuthImage = ({ src, alt, onError, bypassCache = false, ...props }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [jwtToken, setJwtToken] = useState(null);
  const [useFallback, setUseFallback] = useState(false);

  useEffect(() => {
    const fetchJwtToken = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Error fetching JWT token:', error);
        return;
      }
      setJwtToken(data.session?.access_token);
    };

    fetchJwtToken();
  }, []);

  useEffect(() => {
    if (!jwtToken) return;

    const timeoutId = setTimeout(() => {
      setUseFallback(true);
    }, IMAGE_LOAD_TIMEOUT);

    const loadImage = async (attempt = 1) => {
      if (!bypassCache && imgCache.has(src)) {
        //console.log(`Image already in cache: ${src}`);
        setImageSrc(imgCache.get(src));
        clearTimeout(timeoutId);
        return;
      }

      try {
      

        const headers = new Headers();

        if (src.includes('ipfs.pounce.bot')) {
          headers.append('Authorization', `Bearer ${jwtToken}`);
          //console.log("Authorization header added:", headers.get('Authorization'));
        } else {
          console.log("Source does not include 'ipfs.pounce.bot':", src);
        }

        //console.log("Headers before fetch:", [...headers.entries()]);
        //console.log("Source URL:", src);

const response = await fetch(src, { headers });
          if (!response.ok) {
            console.error('Fetch failed:', response.status, response.statusText);
          } else {
            //console.log('Fetch succeeded:', response);
            const data = await response.blob(); // or response.json(), response.text() depending on the content type
            //console.log('Fetched data:', data);
          }
        if (response.ok) {
          const blob = await response.blob();
          const reader = new FileReader();
          reader.onloadend = () => {
            const dataUrl = reader.result;
            const img = new Image();
            img.onload = () => {
              const imageUrl = useBase64 ? dataUrl : URL.createObjectURL(blob);
              setImageSrc(imageUrl);
              if (!bypassCache) {
                if (imgCache.size >= MAX_CACHE_SIZE) {
                  const firstKey = imgCache.keys().next().value;
                  imgCache.delete(firstKey);
                }
                imgCache.set(src, imageUrl);
              }
              clearTimeout(timeoutId);
            };
            img.onerror = (error) => {
              //console.log('Error loading image:', error);
              if (attempt < MAX_RETRIES) {
                loadImage(attempt + 1);
              } else {
                onError && onError(new Error('Failed to load image'));
                setUseFallback(true);
                clearTimeout(timeoutId);
              }
            };
            img.src = dataUrl;
          };
          reader.readAsDataURL(blob);
        } else {
          throw new Error(`Failed to fetch image: ${response.status}`);
        }
      } catch (error) {
        console.log(`Error loading image on attempt ${attempt}`);
        if (attempt < MAX_RETRIES) {
          loadImage(attempt + 1);
        } else {
          onError && onError(error);
          setUseFallback(true);
          clearTimeout(timeoutId);
        }
      }
    };

    loadImage();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [src, jwtToken, onError, bypassCache]);

  if (useFallback) {
    return <img src={src} alt={alt} {...props} />;
  }

  if (!imageSrc) {
    return (
      <>
        <style>
          {`
            .spinner {
              width: 25px;
              height: 25px;
              animation: spin 1s linear infinite;
              margin: 10px;
            }

            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
        </style>
        <svg className="spinner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
        </svg>
      </>
    );
  }

  return <img src={imageSrc} alt={alt} {...props} />;
};

export default AuthImage;

import React, { createContext, useState, useEffect } from 'react';
import supabase from '../services/supabase';

const UserSettingsContext = createContext();

export const UserSettingsProvider = ({ children }) => {
    const [userSettings, setUserSettings] = useState(null);
    const [userId, setUserId] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isNew, setIsNew] = useState(null);

    const fetchUserId = async () => {
        try {
            const { data: { user } } = await supabase.auth.getUser();
        //    console.log("USC 1: user",user);
            if (user) {
                setUserId(user.id);
                console.log("USC 2: User ID:", user.id);
                return user.id;
            }
        } catch (error) {
            console.error('Error fetching user ID:', error.message);
        }
        return null;
    };

    const fetchUserData = async (userId) => {
       // console.log("USC 3: userId:", userId);
        const queryParams = new URLSearchParams(window.location.search);
        const aId = queryParams.get('aId');

        try {
            const { data: { session } } = await supabase.auth.getSession();
            const jwtToken = session?.access_token;

            const url = `https://userdata.pounce.bot/?userId=${userId}${aId ? `&aId=${aId}` : ''}`;

            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }

            const userData = await response.json();
            setUserData(userData);
           // console.log("USC 3: userData:", userData);
            localStorage.setItem('userData', JSON.stringify(userData));
            setUserSettings(userData.settings);
            //console.log("USC 4: userData.settings:", userData.settings);
            setIsNew(userData.isNew);
          //  console.log("Fetched User Data:", userData);
        } catch (error) {
            console.error('Error fetching user data:', error.message);
        }
    };

    const fetchUserDataWithRetry = async (retries = 3, delay = 2000) => {
        let attempts = 0;

        const fetchWithRetry = async () => {
            try {
                let fetchedUserId = userId;
                //console.log("USC fetchWithRetry fetchedUserId:",fetchedUserId);
                if (!fetchedUserId) {
                    fetchedUserId = await fetchUserId();
                    //console.log("USC 2 fetchWithRetry fetchedUserId:",fetchedUserId);
                }
                if (fetchedUserId) {
                    await fetchUserData(fetchedUserId);
                }
            } catch (error) {
                if (attempts < retries) {
                    attempts++;
                    console.error(`Error fetching user data (attempt ${attempts}): ${error.message}`);
                    setTimeout(() => fetchWithRetry(), delay);
                } else {
                    console.error('Error fetching user data:', error.message);
                }
            }
        };
        if (userId) {
        await fetchWithRetry();
        }
    };

    useEffect(() => {
        const initializeUserData = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            if (session) {
                const fetchedUserId = session.user.id;
                if (fetchedUserId) {
                    setUserId(fetchedUserId);
                    await fetchUserDataWithRetry();
                }
            } else {
               // console.log('No session found');
            }
        };
    
        initializeUserData();
    }, []);    

    const refreshUserData = async () => {
        if (userId) {
            await fetchUserData(userId);
        }
    };

    return (
        <UserSettingsContext.Provider value={{ userSettings, userData, userId, setUserSettings, isNew, setIsNew, refreshUserData, fetchUserDataWithRetry, setUserData, setUserId }}>
            {children}
        </UserSettingsContext.Provider>
    );
};

export default UserSettingsContext;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import i18n from '../i18n';
import Flag from 'react-world-flags';
import { styled } from '@mui/system';
import supabase from '../services/supabase';

const languages = [
  { code: 'am', name: 'አማርኛ', country_code: 'ET' },
  { code: 'ar', name: 'العربية', country_code: 'SA' },
  { code: 'bg', name: 'Български', country_code: 'BG' },
  { code: 'bn', name: 'বাংলা', country_code: 'BD' },
  { code: 'cs', name: 'Čeština', country_code: 'CZ' },
  { code: 'da', name: 'Dansk', country_code: 'DK' },
  { code: 'de', name: 'Deutsch', country_code: 'DE' },
  { code: 'el', name: 'Ελληνικά', country_code: 'GR' },
  { code: 'en', name: 'English', country_code: 'US' },
  { code: 'es', name: 'Español', country_code: 'ES' },
  { code: 'fa', name: 'فارسی', country_code: 'IR' },
  { code: 'fi', name: 'Suomi', country_code: 'FI' },
  { code: 'fr', name: 'Français', country_code: 'FR' },
  { code: 'gu', name: 'ગુજરાતી', country_code: 'IN' },
  { code: 'he', name: 'עברית', country_code: 'IL' },
  { code: 'hi', name: 'हिन्दी', country_code: 'IN' },
  { code: 'hr', name: 'Hrvatski', country_code: 'HR' },
  { code: 'hu', name: 'Magyar', country_code: 'HU' },
  { code: 'id', name: 'Bahasa Indonesia', country_code: 'ID' },
  { code: 'it', name: 'Italiano', country_code: 'IT' },
  { code: 'ja', name: '日本語', country_code: 'JP' },
  { code: 'jv', name: 'Basa Jawa', country_code: 'ID' },
  { code: 'ko', name: '한국어', country_code: 'KR' },
  { code: 'lt', name: 'Lietuvių', country_code: 'LT' },
  { code: 'ml', name: 'മലയാളം', country_code: 'IN' },
  { code: 'mr', name: 'मराठी', country_code: 'IN' },
  { code: 'ms', name: 'Bahasa Melayu', country_code: 'MY' },
  { code: 'my', name: 'ဗမာစာ', country_code: 'MM' },
  { code: 'nl', name: 'Nederlands', country_code: 'NL' },
  { code: 'no', name: 'Norsk', country_code: 'NO' },
  { code: 'or', name: 'ଓଡ଼ିଆ', country_code: 'IN' },
  { code: 'pa', name: 'ਪੰਜਾਬੀ', country_code: 'IN' },
  { code: 'pl', name: 'Polski', country_code: 'PL' },
  { code: 'pt', name: 'Português', country_code: 'BR' },
  { code: 'ro', name: 'Română', country_code: 'RO' },
  { code: 'ru', name: 'Русский', country_code: 'RU' },
  { code: 'si', name: 'සිංහල', country_code: 'LK' },
  { code: 'sk', name: 'Slovenčina', country_code: 'SK' },
  { code: 'sl', name: 'Slovenščina', country_code: 'SI' },
  { code: 'sr', name: 'Српски', country_code: 'RS' },
  { code: 'sv', name: 'Svenska', country_code: 'SE' },
  { code: 'sw', name: 'Kiswahili', country_code: 'TZ' },
  { code: 'ta', name: 'தமிழ்', country_code: 'IN' },
  { code: 'te', name: 'తెలుగు', country_code: 'IN' },
  { code: 'th', name: 'ไทย', country_code: 'TH' },
  { code: 'tr', name: 'Türkçe', country_code: 'TR' },
  { code: 'uk', name: 'Українська', country_code: 'UA' },
  { code: 'ur', name: 'اردو', country_code: 'PK' },
  { code: 'vi', name: 'Tiếng Việt', country_code: 'VN' },
  { code: 'zh', name: '中文', country_code: 'CN' },
  { code: 'zu', name: 'isiZulu', country_code: 'ZA' }
];

const CustomSelect = styled(Select)({
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});

const LanguageSelector = ({ theme, userData, userId }) => {
  const { i18n } = useTranslation();
  const browserLanguage = navigator.language.split('-')[0];
  const kvLanguage = userData?.language || null;
  const defaultLanguage = userData?.language || localStorage.getItem('i18nextLng') || i18n.language || browserLanguage || 'en';
  const normalizedLanguage = languages.find(lang => lang.code === defaultLanguage.split('-')[0]) ? defaultLanguage.split('-')[0] : 'en';
  const [language, setLanguage] = React.useState(normalizedLanguage);

/*
  React.useEffect(() => {
    if (kvLanguage === null && !localStorage.getItem('i18nextLng')) {
      const detectedLanguage = languages.find(lang => lang.code === browserLanguage) ? browserLanguage : 'en';
      setLanguage(detectedLanguage);
      i18n.changeLanguage(detectedLanguage);
      localStorage.setItem('i18nextLng', detectedLanguage);
      updateKVStore(userId, { language: detectedLanguage });
    }
  }, [browserLanguage, i18n, kvLanguage, userId]);*/
  React.useEffect(() => {
    if (userData) {
      const kvLanguage = userData.language || null;
      const detectedLanguage = kvLanguage || browserLanguage || 'en';
      setLanguage(detectedLanguage);
      i18n.changeLanguage(detectedLanguage);
      localStorage.setItem('i18nextLng', detectedLanguage);
    }
  }, [userData, browserLanguage, i18n]);
  

  const handleChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('i18nextLng', selectedLanguage);
    updateKVStore(userId, { language: selectedLanguage });
  };

  const selectStyles = {
    backgroundColor: theme === 'dark' ? '#757575' : '#fff',
    color: theme === 'dark' ? '#fff' : '#000',
    height: '30px',
    minWidth: 120,
    padding: '8px',
  };

  const menuItemStyles = {
    backgroundColor: theme === 'dark' ? '#757575' : '#fff',
    color: theme === 'dark' ? '#fff' : '#000',
  };

  const selectedLang = languages.find((lang) => lang.code === language) || languages.find((lang) => lang.code === 'en');

  const updateKVStore = async (userId, updates) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      const jwtToken = session?.access_token;

      const payload = {
        ...updates
      };

      const response = await fetch(`https://userdata.pounce.bot/?userId=${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        console.log("Updated successfully.");
      } else {
        throw new Error('Failed to update');
      }
    } catch (error) {
      console.error("Error updating:", error.message);
    }
  };

  return (
    <CustomSelect
      value={language}
      onChange={handleChange}
      displayEmpty
      style={selectStyles}
      renderValue={(selected) => {
        const selectedLang = languages.find((lang) => lang.code === selected) || { code: 'en', name: 'English', country_code: 'US' };
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Flag code={selectedLang.country_code} style={{ marginRight: '8px', width: '20px', height: '15px' }} />
            {selectedLang.name} - {selectedLang.code.toUpperCase()}
          </div>
        );
      }}
      MenuProps={{
        PaperProps: {
          style: {
            backgroundColor: theme === 'dark' ? '#424242' : '#fff',
          },
        },
      }}
    >
      {languages.map(({ code, name, country_code }) => (
        <MenuItem key={code} value={code} style={menuItemStyles}>
          <ListItemIcon>
            <Flag code={country_code} style={{ width: '20px', height: '15px' }} />
          </ListItemIcon>
          <ListItemText primary={`${name} - ${code.toUpperCase()}`} />
        </MenuItem>
      ))}
    </CustomSelect>
  );
};

export default LanguageSelector;

import React, { useState, useEffect, useRef, useContext } from 'react';
import supabase from '../../services/supabase';
import AuthImage from '../../services/AuthImage';
import '../../assets/styles/NewCoins.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useSSE from '../../hooks/useSSE';
import Modal from 'react-modal';
import SettingsForm from '../../forms/solana/SettingsForm';
import SettingsTabContext from '../../hooks/SettingsTabContext';
import UserSettingsContext from '../../hooks/UserSettingsContext';

const Upcoming = ({ theme, activeModal, setActiveModal, onPin, activeModuleCount }) => {
  const tokens = useSSE(['Upcoming']);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const { userSettings } = useContext(UserSettingsContext);
  const nodeRef = useRef(null);
  const wideClassThreshold = 3;

  const handleBuy = async (token, amount) => {
    setTransactionStatus({ status: 'sending' });

    try {
      const { data: { session } } = await supabase.auth.getSession();
      const jwtToken = session?.access_token;
      const userId = localStorage.getItem('userId');
      const network = token.network;

      const response = await fetch('https://trade.pounce.bot/buy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify({
          userId,
          jwtToken,
          network,
          mint: token.mint,
          amount
        })
      });

      const result = await response.json();
      console.log('Response from Server:', result);

      if (response.ok) {
        setTransactionStatus({ status: 'successful', transactionLink: result.transactionLink });
      } else {
        setTransactionStatus({ status: 'failed', transactionLink: result.error });
      }
    } catch (error) {
      console.error('Error sending data to Server:', error);
      setTransactionStatus({ status: 'failed', transactionLink: error.message });
    }
  };

  const renderStatusDot = token => {
    const { status, transactionLink } = transactionStatus || {};
    if (!status) return null;
    const statusMap = { sending: 'orange', successful: 'green', failed: 'red' };
    return (
      <span className={`status-dot ${statusMap[status]}`}>
        <svg className="status-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"/>
        </svg>
        {`Transaction ${status} ${transactionLink ? transactionLink : ''}`}
      </span>
    );
  };

  if (!tokens.length) return <div className={`new-token ${theme}`}>Waiting for new tokens... Upcoming Token</div>;

  return (
    <div className={`new-token ${theme}`}>
      <TransitionGroup>
        {tokens.map((token) => {
          return (
            <CSSTransition
              key={token.mint}
              timeout={250}
              classNames="fade"
              in={userSettings ? userSettings.animationsEnabled !== false : true}
              nodeRef={nodeRef}
            >
              <div ref={nodeRef} className="token-account">
                <div className={`top-row ${activeModuleCount === 4 ? 'modules-4' : activeModuleCount === 5 ? 'modules-5' : ''}`}>
                  {token.img && (
                    <AuthImage
                      key={token.img}
                      src={token.img}
                      alt={token.name || token.mint}
                      className="token-image"
                      bypassCache={true}
                      style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px', alignSelf: 'center' }}
                    />
                  )}
                  <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                      <span style={{ fontWeight: 'bold' }}>
                        {token.name ? `${token.name.slice(0, activeModuleCount >= 4 ? 10 : activeModuleCount <= 3 ? 20 : 14)} ` : ''}
                      </span>
                      <span>
                        &nbsp;{token.ticker ? `(${token.ticker.startsWith('$') ? token.ticker.slice(0, activeModuleCount >= 4 ? 10 : undefined) : `$${token.ticker.slice(0, activeModuleCount >= 4 ? 10 : undefined)}`}) ` : ''}&nbsp;
                      </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span
                        title={`Click to copy address: ${token.mint}`}
                        onClick={() => navigator.clipboard.writeText(token.mint)}
                        style={{ cursor: 'pointer', paddingLeft: '0rem' }}
                        className="mint-address"
                      >
                        {token.mint ? `${token.mint.slice(0, 4)}...${token.mint.slice(-4)}` : ''}
                      </span>
                      <div className="social-links">
                        {token.twitter && (
                          <a href={token.twitter} target="_blank" rel="noopener noreferrer" className={`token-link ${theme}`} title={`Visit token twitter: ${token.twitter}`}>
                            <svg className="social-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <path fill="currentColor" d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" />
                            </svg>
                          </a>
                        )}
                        {token.telegram && (
                          <a href={token.telegram} target="_blank" rel="noopener noreferrer" className={`token-link ${theme}`} title={`Visit token telegram: ${token.telegram}`}>
                            <svg className="social-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm121.8 169.9l-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z" />
                            </svg>
                          </a>
                        )}
                        {token.website && (
                          <a href={token.website} target="_blank" rel="noopener noreferrer" className={`token-link ${theme}`} title={`Visit token website: ${token.website}`}>
                            <svg className="social-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <path fill="currentColor" d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z" />
                            </svg>
                          </a>
                        )}
                        <div className="right">
                          <a href={token.network === 1 ? `https://dexscreener.com/solana/${token.mint}` : `https://pump.fun/${token.mint}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`token-link ${theme}`}
                            title={token.network === 1 ? "View token on DexScreener" : "View token on Pump.Fun"}>
                            <svg className="social-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <path fill="currentColor" d="M432 320H400a16 16 0 0 0 -16 16V448H64V128H208a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16H48A48 48 0 0 0 0 112V464a48 48 0 0 0 48 48H400a48 48 0 0 0 48-48V336A16 16 0 0 0 432 320zM488 0h-128c-21.4 0-32.1 25.9-17 41l35.7 35.7L135 320.4a24 24 0 0 0 0 34L157.7 377a24 24 0 0 0 34 0L435.3 133.3 471 169c15 15 41 4.5 41-17V24A24 24 0 0 0 488 0z" />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`button-group ${activeModuleCount < wideClassThreshold ? 'button-wide' : ''}`}>
                  {(userSettings?.quickBuy?.buyAmounts || [25, 10, 5, 1]).map((amount, idx) => (
                    <button title={`Buy for ${amount} ${userSettings?.expert?.currency || 'SOL'}`} key={idx} onClick={() => handleBuy(token, amount)}>{amount} {userSettings?.expert?.currency || 'SOL'}</button>
                  ))}
                </div>
                {renderStatusDot(token)}
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
};

export default Upcoming;

import React, { useState, useEffect, useContext } from 'react';
import '../assets/styles/Header.css';
import Modal from 'react-modal';
import SettingsForm from '../forms/solana/SettingsForm';
import ResetPasswordForm from '../forms/ResetPasswordForm';
import Sidebar from './Sidebar';
import UserSettingsContext from '../hooks/UserSettingsContext';
import SettingsTabContext from '../hooks/SettingsTabContext';
import supabase from '../services/supabase';
import LanguageSelector from '../components/LanguageSelector';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function Header({
    onThemeToggle, 
    onAutoBuyToggle,
    onCopyTradeToggle,
    onNewCoinsToggle,
    onUpcomingCoinsToggle,
    onTrackerToggle,
    onTrendingToggle,
    onWalletToggle,
    newCoinsSwitch,
    upcomingCoinsSwitch,
    trackerSwitch,
    trendingSwitch,
    walletSwitch,
    activeModal,
    setActiveModal,
    onPin,
    toggleHeaderVisibility,
    isHeaderVisible
}) {
    const { userSettings, setUserSettings, userId, setUserId, isNew, setIsNew, fetchUserDataWithRetry, userData, refreshUserData, setUserData  } = useContext(UserSettingsContext);
    const { setActiveTab } = useContext(SettingsTabContext); 
    const [activeTab, setActiveTabState] = useState('bot');
    const [theme, setTheme] = useState(userData?.theme || 'light'); 
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    /*
    const [themeSwitch, setThemeSwitch] = useState(() => {
        const storedTheme = userData?.theme;
        if (storedTheme) {
            return storedTheme === 'dark';
        } else {
            return false; 
        }
    });    */
    
    const [themeSwitch, setThemeSwitch] = useState(() => {
        return userData?.theme === 'dark';
    });

    const [autoBuySwitch, setAutoBuySwitch] = useState(false);
    const [copyTradeSwitch, setCopyTradeSwitch] = useState(false);
    const [settingsAutoBuy, setSettingsAutoBuy] = useState(false);
    const [settingsCopyTrade, setSettingsCopyTrade] = useState(false);
    const [autoBuy, setAutoBuy] = useState(false);
    const [copyTrade, setCopyTrade] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [activeSettingsTab, setActiveSettingsTab] = useState('quick-buy');
    const [searchInput, setSearchInput] = useState('');
    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        if (userSettings && isNew) {
            setActiveModal('wallet');  
        }
    }, [userId, isNew, userSettings]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const accessToken = params.get('access_token');
        const type = params.get('type');

        if (accessToken && type === 'recovery') {
          setAccessToken(accessToken);
          setActiveModal('resetPassword');
        }
      }, []);

    useEffect(() => {
        //console.log("userData?.settings?.copyTrade?.copyTradeMode",userData?.settings?.copyTrade?.copyTradeMode);
        if (userData?.settings?.copyTrade?.copyTradeMode !== 'none') {
            setSettingsCopyTrade(true);
        }
    }, [userData]);

    useEffect(() => {
        if (userData?.settings?.autoBuy?.autoBuyMode?.raydium || userData?.settings?.autoBuy?.autoBuyMode?.pumpFun) {
            setSettingsAutoBuy(true);
        }
    }, [userData]);    

    useEffect(() => {
        if (userData?.autoBuy !== undefined) {
            setAutoBuy(userData.autoBuy);
            setAutoBuySwitch(userData.autoBuy);
        }
        if (userData?.copyTrade !== undefined) {
            setCopyTrade(userData.copyTrade);
            setCopyTradeSwitch(userData.copyTrade);
        }
    }, [userData]);
    
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
      };
    
    const handleLogout = async () => {
        try {
            const { error } = await supabase.auth.signOut();
            if (error) {
                throw error;
            }
            localStorage.removeItem('userId');
            localStorage.removeItem('userData');
            localStorage.removeItem('userSettings');
            localStorage.clear();
            window.location.reload();
        } catch (error) {
            console.error('Error logging out:', error.message);
        }
    };        

    const handleTabChange = (tab) => {
        setActiveTabState(tab);
    };
      
    const handleThemeToggle = () => {
        const newTheme = !themeSwitch ? 'dark' : 'light';
        setTheme(newTheme);
        setThemeSwitch(!themeSwitch);
        onThemeToggle(newTheme);
        if (userId) {
            updateKVStore(userId, { theme: newTheme });
        }
        localStorage.setItem('theme', newTheme);
        localStorage.setItem('themeSwitch', !themeSwitch);
    };
   
    useEffect(() => {
        if (userData) {
            setTheme(userData.theme || 'light');
            setThemeSwitch(userData.theme === 'dark');
        }
    }, [userData]);    
    
    const openSettings = () => {
        setActiveTab('quick-buy'); 
        setActiveModal('settings');
    };

    const closeModal = () => {
        setActiveModal(null);
        setAccessToken(null);
    };

    const handleAutoBuyToggle = async () => {
        if (settingsAutoBuy) {
            const newAutoBuyState = !autoBuy;
            setAutoBuySwitch(newAutoBuyState);
            setAutoBuy(newAutoBuyState);
            onAutoBuyToggle(newAutoBuyState);
            if (userId) {
                await updateKVStore(userId, { autoBuy: newAutoBuyState });
            }
        } else {
            setActiveTab('auto-buy');
            setActiveModal('settings');
        }
    };    

    const handleCopyTradeToggle = async () => {
        if (settingsCopyTrade) {
            const newCopyTradeState = !copyTrade;
            setCopyTradeSwitch(newCopyTradeState);
            setCopyTrade(newCopyTradeState);
            onCopyTradeToggle(newCopyTradeState);
            if (userId) {
                await updateKVStore(userId, { copyTrade: newCopyTradeState });
            }
        } else {
            setActiveTab('copyTrade');
            setActiveModal('settings');
        }
    };
    
    const handleNewCoinsToggle = () => {
        onNewCoinsToggle(!newCoinsSwitch);
    };
    
    const handleUpcomingCoinsToggle = () => {
        onUpcomingCoinsToggle(!upcomingCoinsSwitch);
    };
    
    const handleTrackerToggle = () => {
        onTrackerToggle(!trackerSwitch);
    };

    const handleTrendingToggle = () => {
        onTrendingToggle(!trendingSwitch);
    };

    const handleWalletToggle = () => {
        onWalletToggle(!walletSwitch);
    };

    const handleSearch = async (event) => {
        if (event.key === 'Enter') {
            const coin = await fetchCoin(searchInput);
            if (coin) {
                onPin(coin);
                setSearchInput('');
            }
        }
    };

    const mapCoinData = (data) => {
        // Check if the structure is as expected
     if (!data || !data.data || !data.data.data || !data.data.data.token) {
       console.error("Invalid API data structure", data);
       return {}; // Return an empty object or handle the error as needed
     }
    
     // Correctly accessing the deeply nested token data
     const tokenData = data.data.data.token; 
     
        const {
          address, 
          symbol, 
          name, 
          decimals, 
          logo, 
          liquidity, 
          total_supply, 
          launchpad, 
          launchpad_progress, 
          launchpad_status, 
          top_10_holder_rate, 
          renounced_mint, 
          renounced_freeze_account, 
          burn_ratio, 
          burn_status, 
          rug_ratio, 
          dexscr_ad, 
          dexscr_update_link, 
          holder_count, 
          hot_level, 
          is_honeypot, 
          market_cap, 
          max_supply, 
          net_in_volume_1h, 
          net_in_volume_1m, 
          net_in_volume_5m, 
          net_in_volume_6h, 
          net_in_volume_24h, 
          pool_info, 
          price, 
          price_1h, 
          price_1m, 
          price_5m, 
          price_6h, 
          price_24h, 
          sell_tax, 
          sell_volume_1h, 
          sell_volume_1m, 
          sell_volume_5m, 
          sell_volume_6h, 
          sell_volume_24h, 
          sells_1h, 
          sells_1m, 
          sells_5m, 
          sells_6h, 
          sells_24h, 
          swaps_1h, 
          swaps_1m, 
          swaps_5m, 
          swaps_6h, 
          swaps_24h, 
          volume_1h, 
          volume_1m, 
          volume_5m, 
          volume_6h, 
          volume_24h,
          socials = {}
        } = tokenData;
      
        const extractedData = {
          mint: address,
          ticker: symbol,
          name: name,
          decimals: decimals,
          img: logo,
          liquidity: liquidity,
          total_supply: total_supply,
          origin: launchpad,
          launchpad_progress: launchpad_progress,
          origin_status: launchpad_status,
          top_10_holder_rate: top_10_holder_rate * 100,
          renounced_mint: renounced_mint,
          renounced_freeze_account: renounced_freeze_account,
          burn_ratio: burn_ratio || undefined,
          burn_status: burn_status,
          rug_ratio: rug_ratio !== null ? 1 : null,
          dexscr_ad: dexscr_ad,
          dexscr_update_link: dexscr_update_link,
          holder_count: holder_count,
          hot_level: hot_level,
          is_honeypot: is_honeypot,
          market_cap: market_cap,
          max_supply: max_supply,
          net_in_volume_1h: net_in_volume_1h,
          net_in_volume_1m: net_in_volume_1m,
          net_in_volume_5m: net_in_volume_5m,
          net_in_volume_6h: net_in_volume_6h,
          net_in_volume_24h: net_in_volume_24h,
          pool_info: pool_info,
          price: price,
          price_1h: price_1h,
          price_1m: price_1m,
          price_5m: price_5m,
          price_6h: price_6h,
          price_24h: price_24h,
          sell_tax: sell_tax,
          sell_volume_1h: sell_volume_1h,
          sell_volume_1m: sell_volume_1m,
          sell_volume_5m: sell_volume_5m,
          sell_volume_6h: sell_volume_6h,
          sell_volume_24h: sell_volume_24h,
          sells_1h: sells_1h,
          sells_1m: sells_1m,
          sells_5m: sells_5m,
          sells_6h: sells_6h,
          sells_24h: sells_24h,
          swaps_1h: swaps_1h,
          swaps_1m: swaps_1m,
          swaps_5m: swaps_5m,
          swaps_6h: swaps_6h,
          swaps_24h: swaps_24h,
          volume_1h: volume_1h,
          volume_1m: volume_1m,
          volume_5m: volume_5m,
          volume_6h: volume_6h,
          volume_24h: volume_24h,
          network: (launchpad === "Pump.fun" && launchpad_status === 0) ? 2 : 1,
        };
      
        // Mapping social media links
        if (socials.twitter_username) extractedData.twitter = `https://x.com/${socials.twitter_username}`;
        if (socials.website) extractedData.website = socials.website;
        if (socials.telegram) extractedData.telegram = socials.telegram;
        if (socials.bitbucket) extractedData.bitbucket = socials.bitbucket;
        if (socials.discord) extractedData.discord = socials.discord;
        if (socials.facebook) extractedData.facebook = socials.facebook;
        if (socials.github) extractedData.github = socials.github;
        if (socials.instagram) extractedData.instagram = socials.instagram;
        if (socials.linkedin) extractedData.linkedin = socials.linkedin;
        if (socials.medium) extractedData.medium = socials.medium;
        if (socials.reddit) extractedData.reddit = socials.reddit;
        if (socials.tiktok) extractedData.tiktok = socials.tiktok;
        if (socials.youtube) extractedData.youtube = socials.youtube;
      
        return extractedData;
      };
  // Function to fetch data
const fetchCoin = async (contractAddress) => {
    console.log("fetchCoin start");
    try {
      if (!contractAddress) {
        console.error("contractAddress is undefined");
        return null;
      }
  
      // Send message to content script (extension)
      window.postMessage({ type: "GET_TOKEN_INFO", contractAddress }, "*");
      console.log("postMessage");
  
      // Listen for response from content script
      return new Promise((resolve, reject) => {
        console.log("new Promise");
        const handleMessage = (event) => {
          if (event.data.type && event.data.type === "TOKEN_INFO_RESPONSE") {
            window.removeEventListener("message", handleMessage);
            console.log("window.removeEventListener");
  
            if (event.data.data.success) {
              console.log("event.data.data.success");
              console.log("event.data.data:", event.data.data);
  
              const mappedData = mapCoinData(event.data.data);
              console.log("mappedData Header:", mappedData);
  
              resolve(mappedData);
            } else {
                console.error("Error fetching token info:", event.data.data.error);
                resolve(null); // Return null on error instead of rejecting
            }
          }
        };
        window.addEventListener("message", handleMessage);
        console.log("window.addEventListener");
      });
    } catch (error) {
      console.error('Error fetching coin data:', error.message);
      return null;
    }
  };
  
    
    const updateKVStore = async (userId, updates) => {
        try {
            const { data: { session } } = await supabase.auth.getSession();
            const jwtToken = session?.access_token;
    
            const payload = {
                ...updates
            };
    
            //console.log("Attempting to save:", { userId, ...updates });
            const response = await fetch(`https://userdata.pounce.bot/?userId=${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify(payload)
            });
    
            if (response.ok) {
                //console.log("Updated successfully.");
                setIsNew(false);
                await refreshUserData(); // Ensure user data is refreshed after update
    
                const processAutoMode = async (mode, value) => {
                    try {
                        const { data: { session } } = await supabase.auth.getSession();
                        const jwtToken = session?.access_token;
                
                        if (!jwtToken) {
                            throw new Error('JWT token is not available');
                        }
                
                        const modeUrl = `https://afk.pounce.bot/${mode}?userId=${userId}`;
                        
                        const modeResponse = await fetch(modeUrl, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${jwtToken}`
                            },
                            body: JSON.stringify({ value })
                        });
                
                        if (!modeResponse.ok) {
                            const errorText = await modeResponse.text();
                            throw new Error(`Failed to update ${mode} in automode: ${modeResponse.status} - ${errorText}`);
                        }
                    } catch (error) {
                        console.error(`Error in processAutoMode: ${error.message}`);
                        throw error;
                    }
                };                
    
                if (updates.autoBuy !== undefined) {
                    const message = updates.autoBuy ? "Auto Buy Activated" : "Auto Buy Deactivated";
                    //console.log(message); // This will be replaced with a Snackbar message in the future
                    setSnackbarMessage(message);
                    setSnackbarOpen(true);
    
                    await processAutoMode('AUTOBUY', updates.autoBuy);
                }
    
                if (updates.copyTrade !== undefined) {
                    const message = updates.copyTrade ? "Copy Trade Activated" : "Copy Trade Deactivated";
                    //console.log(message); // This will be replaced with a Snackbar message in the future
                    setSnackbarMessage(message);
                    setSnackbarOpen(true);
    
                    await processAutoMode('COPYTRADE', updates.copyTrade);
                }
            } else {
                throw new Error('Failed to update KV store');
            }
        } catch (error) {
            console.error("Error updating KV store:", error.message);
        }
    };
    
    

    return (
        <div>
          <div className={`tabs ${theme}`}>
           <div className={`tab ${activeTab === 'bot' ? 'active' : ''}`} onClick={() => handleTabChange('bot')}>Bot</div>
           <div className={`tab ${activeTab === 'create-token' ? 'active' : ''}`} onClick={() => handleTabChange('create-token')}>Create Token</div>
           <div className={`tab ${activeTab === 'affiliates' ? 'active' : ''}`} onClick={() => handleTabChange('affiliates')}>Affiliates</div>
           <div className="language-selector">
                <LanguageSelector theme={theme} userData={userData} userId={userId} />
            </div>
        </div>         
            <div className={`header ${theme}`}>
                {activeTab === 'bot' && (
                    <>
                        <button className={`hamburger-menu ${theme}`} onClick={() => setSidebarOpen(!sidebarOpen)} title={`Show Menu`}>
                            &#9776;
                        </button>
                        <input 
                            className="search-bar" 
                            type="search" 
                            title="Enter Token Address and press Enter"
                            placeholder="Search Token..." 
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            onKeyPress={handleSearch}
                        />
                        <a className="settings-link" href="#!" onClick={openSettings} title={`Open Bot Settings`}>
                            <svg className="icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4 .6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"/>
                            </svg>
                        </a> 
                        <a className={`wallet-link ${theme}`} href="#!" onClick={() => setActiveModal('wallet')} title={`Bot Wallet Public Key`}>
                            <svg className="icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M461.2 128H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h384c8.8 0 16-7.2 16-16 0-26.5-21.5-48-48-48H64C28.7 32 0 60.7 0 96v320c0 35.4 28.7 64 64 64h397.2c28 0 50.8-21.5 50.8-48V176c0-26.5-22.8-48-50.8-48zM416 336c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"/>
                            </svg>
                        </a>
                    </>
                )}
                {activeTab === 'create-token' && (
                    <div>
                        {/* Components or layout specific to the 'Create Token' tab */}
                    </div>
                )}
                {activeTab === 'affiliates' && (
                    <div>
                        {/* Components or layout specific to the 'Affiliates' tab */}
                    </div>
                )}
                <div className="right-section">
                    <a className="logout-link" href="#!" onClick={handleLogout} title={`Logout`}>
                        <svg className="icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path fill="currentColor" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"/>
                        </svg>
                    </a>
                    <label className="switch">
                        <input type="checkbox" checked={themeSwitch} onChange={handleThemeToggle} />
                        <div className="slider round">
                            <span className="icon2 sun">&#x2600;&#xFE0E;</span>
                            <span className="icon2 moon">&#x1F319;</span>
                        </div>
                    </label>
                    <button onClick={toggleHeaderVisibility} className={`toggle-header-button-header ${theme}`} title={`Hide Header`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M246.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 109.3 361.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160zm160 352l-160-160c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 301.3 361.4 438.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3z" />
                        </svg>
                    </button>
                 </div>
                <Modal
                    isOpen={activeModal === 'settings'}
                    onRequestClose={closeModal}
                    contentLabel="Settings Modal"
                    overlayClassName="customOverlay"
                    className="customContent"
                    style={{ overlay: { top: '0px' }, content: { top: '50px' } }}
                >
                    <SettingsForm theme={theme} userId={userId} onClose={closeModal} refreshUserData={fetchUserDataWithRetry} />
                </Modal>
                <Modal
                    isOpen={activeModal === 'resetPassword'}
                    onRequestClose={closeModal}
                    contentLabel="Reset Password"
                    overlayClassName="customOverlay"
                    className="customContent"
                    style={{ overlay: { top: '0px' }, content: { top: '50px' } }}
                >
                    <ResetPasswordForm accessToken={accessToken} theme={theme} userId={userId}  refreshUserData={fetchUserDataWithRetry} onClose={closeModal} />
                </Modal>
            </div>
            {sidebarOpen && (
                <div className="sidebar-overlay" onClick={() => setSidebarOpen(false)}></div>
            )}
            <Sidebar
                autoBuySwitch={autoBuySwitch}
                copyTradeSwitch={copyTradeSwitch}
                newCoinsSwitch={newCoinsSwitch}
                upcomingCoinsSwitch={upcomingCoinsSwitch}
                trackerSwitch={trackerSwitch}
                trendingSwitch={trendingSwitch}
                walletSwitch={walletSwitch}
                handleAutoBuyToggle={handleAutoBuyToggle}
                handleCopyTradeToggle={handleCopyTradeToggle}
                handleNewCoinsToggle={handleNewCoinsToggle}
                handleUpcomingCoinsToggle={handleUpcomingCoinsToggle}
                handleTrackerToggle={handleTrackerToggle}
                handleTrendingToggle={handleTrendingToggle}
                handleWalletToggle={handleWalletToggle}
                userData={userData}
                isOpen={sidebarOpen}
                theme={theme}
                onClose={() => setSidebarOpen(false)} 
            />    
            <Snackbar 
                open={snackbarOpen} 
                autoHideDuration={6000} 
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>    
    );
}

export default Header;

import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import SettingsForm from '../forms/solana/SettingsForm';
import CreateWalletForm from '../forms/solana/CreateWalletForm';
import New from '../components/coins/New';
import Trending from '../components/coins/Trending';
import Tracker from '../components/coins/Tracker';
import Upcoming from '../components/coins/Upcoming';
import Wallet from '../forms/solana/Wallet';
import Pinned from '../components/coins/Pinned';  // Import the Pinned component
import '../assets/styles/Dash.css';
import UserSettingsContext from '../hooks/UserSettingsContext';

Modal.setAppElement('#root');

function Dash({
  onThemeToggle, 
  newCoinsSwitch,
  upcomingCoinsSwitch,
  trackerSwitch,
  trendingSwitch,
  walletSwitch,
  setUserId,
  activeModal,
  setActiveModal,
  setNewCoinsSwitch,
  setUpcomingCoinsSwitch,
  setWalletSwitch,
  theme,
  setTheme,
  activeCount,
  pinnedCoins, 
  onPin, 
  onUnpin,
  activeModuleCount,
  toggleHeaderVisibility,
  isHeaderVisible
}) {
  const { userSettings, userId, isNew, setUserSettings, setIsNew, fetchUserDataWithRetry, userData } = useContext(UserSettingsContext);

  // Set to open wallet modal
  const openWalletModal = () => setActiveModal('wallet');
  // Set to open settings modal
  const openSettingsModal = () => setActiveModal('settings');
  // Close any modal
  const closeModal = () => setActiveModal(null);

  useEffect(() => {
    //console.log("Active modal state updated:", activeModal);
  }, [activeModal]);

  useEffect(() => {
    // Ensure that at least one switch is always on
    if (!newCoinsSwitch && !upcomingCoinsSwitch && !trackerSwitch && !trendingSwitch && !walletSwitch) {
      setNewCoinsSwitch(true);  // Default to enabling NewCoins if everything else is off
    }
  }, [newCoinsSwitch, upcomingCoinsSwitch, trackerSwitch, trendingSwitch, walletSwitch]);

  // Use useEffect to update local storage when states change
  useEffect(() => {
    localStorage.setItem('newCoinsSwitch', JSON.stringify(newCoinsSwitch));
  }, [newCoinsSwitch]);

  useEffect(() => {
    localStorage.setItem('upcomingCoinsSwitch', JSON.stringify(upcomingCoinsSwitch));
  }, [upcomingCoinsSwitch]);

  useEffect(() => {
    localStorage.setItem('trackerSwitch', JSON.stringify(trackerSwitch));
  }, [trackerSwitch]);

  useEffect(() => {
    localStorage.setItem('trendingSwitch', JSON.stringify(trendingSwitch));
  }, [trendingSwitch]);

  useEffect(() => {
    localStorage.setItem('walletSwitch', JSON.stringify(walletSwitch));
  }, [walletSwitch]);

  useEffect(() => {
    setTheme(theme); 
    localStorage.setItem('theme', theme);
  }, [theme]);

  const handleThemeToggle = (newTheme) => {
    setTheme(newTheme);
  };

  useEffect(() => {
    const savedUserId = localStorage.getItem('userId');
    if (savedUserId && !userData) {
      fetchUserDataWithRetry(savedUserId);
    }
  }, []);
  
  const getFlexBasisAndLastActive = () => {
    const modules = [
      { name: 'newCoins', active: newCoinsSwitch },
      { name: 'upcomingCoins', active: upcomingCoinsSwitch && userData && userData.network === 1 },
      { name: 'trending', active: trendingSwitch },
      { name: 'tracker', active: trackerSwitch },
      { name: 'wallet', active: walletSwitch },
    ];
  
    const activeModules = modules.filter(module => module.active);
    const lastActiveModule = activeModules.length > 0 ? activeModules[activeModules.length - 1].name : null;
  
    const flexBasis = activeModules.reduce((acc, module) => {
      if (activeModules.length === 5) {
        acc[module.name] = '20%';
      } else if (activeModules.length === 4) {
        acc[module.name] = '23%';
      } else if (activeModules.length === 3) {
        if (activeModules.some(m => m.name === 'wallet')) {
          acc[module.name] = module.name === 'wallet' ? '18%' : '38%';
        } else {
          acc[module.name] = '30%';
        }
      } else if (activeModules.length === 2) {
        acc[module.name] = '50%';
      } else if (activeModules.length === 1) {
        acc[module.name] = '100%';
      }
      return acc;
    }, {});
  
    return { flexBasis, lastActiveModule };
  };
  
  const { flexBasis, lastActiveModule } = getFlexBasisAndLastActive();


return (
  <>
    <div className={`dash ${theme}`}>
      {pinnedCoins.length > 0 && (
        <div className="pinned-module">
          <Pinned theme={theme} activeModal={activeModal} setActiveModal={setActiveModal} coins={pinnedCoins} onUnpin={onUnpin} />
        </div>
      )}
      {activeModal === 'wallet' && (
        <Modal
          isOpen={activeModal === 'wallet'}
          onRequestClose={closeModal}
          contentLabel="Create Wallet Modal"
          overlayClassName="customOverlay"
          className="customContent"
          shouldCloseOnOverlayClick={false}
          style={{ overlay: { top: '0px'}, content: { top: '50px' } }}
        >
          <CreateWalletForm theme={theme} userId={userId} setIsNew={setIsNew} onClose={closeModal} />
        </Modal>
      )}
      <div style={{ display: 'flex' }}>
      {newCoinsSwitch && (
        <div style={{ flex: `1 0 ${flexBasis.newCoins}`, display: 'block', position: lastActiveModule === 'newCoins' ? 'relative' : 'static' }}>
          {lastActiveModule === 'newCoins' && !isHeaderVisible && (
             <button onClick={toggleHeaderVisibility} className={`toggle-header-button ${theme}`} title={`Show Header`}>
             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
               <path fill="currentColor" d="M246.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 402.7 361.4 265.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-160 160zm160-352l-160 160c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7 361.4 73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z" />
                </svg>
          </button>
          )}
          <New theme={theme} userId={userId} setActiveModal={setActiveModal} activeModal={activeModal} onPin={onPin} activeModuleCount={activeModuleCount}/>
        </div>
      )}
      {upcomingCoinsSwitch && userData && userData.network === 1 && (
        <div style={{ flex: `1 0 ${flexBasis.upcomingCoins}`, display: 'block', position: lastActiveModule === 'upcomingCoins' ? 'relative' : 'static' }}>
          {lastActiveModule === 'upcomingCoins' && !isHeaderVisible && (
             <button onClick={toggleHeaderVisibility} className={`toggle-header-button ${theme}`} title={`Show Header`}>
             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
               <path fill="currentColor" d="M246.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 402.7 361.4 265.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-160 160zm160-352l-160 160c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7 361.4 73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z" />
                </svg>
          </button>
          )}
          <Upcoming theme={theme} userId={userId} setActiveModal={setActiveModal} activeModal={activeModal} onPin={onPin} activeModuleCount={activeModuleCount}/>
        </div>
      )}
      {trendingSwitch && (
        <div style={{ flex: `1 0 ${flexBasis.trending}`, display: 'block', position: lastActiveModule === 'trending' ? 'relative' : 'static' }}>
          {lastActiveModule === 'trending' && !isHeaderVisible && (
             <button onClick={toggleHeaderVisibility} className={`toggle-header-button ${theme}`} title={`Show Header`}>
             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
               <path fill="currentColor" d="M246.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 402.7 361.4 265.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-160 160zm160-352l-160 160c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7 361.4 73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z" />
                </svg>
          </button>
          )}
          <Trending theme={theme} userId={userId} setActiveModal={setActiveModal} activeModal={activeModal} onPin={onPin} activeModuleCount={activeModuleCount}/>
        </div>
      )}
      {trackerSwitch && (
        <div style={{ flex: `1 0 ${flexBasis.tracker}`, display: 'block', position: lastActiveModule === 'tracker' ? 'relative' : 'static' }}>
          {lastActiveModule === 'tracker' && !isHeaderVisible && (
             <button onClick={toggleHeaderVisibility} className={`toggle-header-button ${theme}`} title={`Show Header`}>
             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
               <path fill="currentColor" d="M246.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 402.7 361.4 265.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-160 160zm160-352l-160 160c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7 361.4 73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z" />
                </svg>
          </button>
          )}
          <Tracker theme={theme} userId={userId} setActiveModal={setActiveModal} activeModal={activeModal} onPin={onPin} activeModuleCount={activeModuleCount}/>
        </div>
      )}
      {walletSwitch && (
        <div style={{ flex: `1 0 ${flexBasis.wallet}`, display: 'block', position: lastActiveModule === 'wallet' ? 'relative' : 'static' }}>
          {lastActiveModule === 'wallet' && !isHeaderVisible && (
              <button onClick={toggleHeaderVisibility} className={`toggle-header-button ${theme}`} title={`Show Header`}>
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                 <path fill="currentColor" d="M246.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 402.7 361.4 265.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-160 160zm160-352l-160 160c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7 361.4 73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z" />
                  </svg>
            </button>
          )}
          <Wallet theme={theme} userId={userId} isNew={isNew} setActiveModal={setActiveModal} activeModal={activeModal} onPin={onPin} activeModuleCount={activeModuleCount}/>
        </div>
      )}
    </div>    
    </div>
  </>
);
}

export default Dash;

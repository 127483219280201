import React, { useState, useEffect, useContext } from 'react';
import { Keypair } from '@solana/web3.js';
import bs58 from 'bs58';
import '../../assets/styles/CreateWalletForm.css';
import Modal from 'react-modal';
import UserSettingsContext from '../../hooks/UserSettingsContext';
import supabase from '../../services/supabase';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const useFetchUserId = () => {
  const [userId, setUserId] = useState(localStorage.getItem('userId'));

  useEffect(() => {
    const fetchUserId = async () => {
      if (!userId) {
        try {
          const { data: { user } } = await supabase.auth.getUser();
          if (user) {
            localStorage.setItem('userId', user.id);
            setUserId(user.id);
          }
        } catch (error) {
          console.error('Error fetching user ID:', error.message);
        }
      }
    };

    fetchUserId();
  }, [userId]);

  return userId;
};

const CreateWalletForm = ({ theme, setIsNew, onClose }) => {
  const { userData, refreshUserData } = useContext(UserSettingsContext);
  const [privateKey, setPrivateKey] = useState('');
  const [walletImported, setWalletImported] = useState(() => {
    return JSON.parse(localStorage.getItem('walletImported')) || false;
  });
  const [walletCreated, setWalletCreated] = useState(() => {
    return JSON.parse(localStorage.getItem('walletCreated')) || false;
  });
  const [walletAddress, setWalletAddress] = useState('');
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const userId = useFetchUserId();

  useEffect(() => {
    if (userData && userData.uId) {
      setWalletAddress(userData.uId);
      setWalletCreated(true);
      setWalletImported(true);
    } else {
      setWalletCreated(false);
      setWalletImported(false);
    }
  }, [userData]);

  const fetchUserId = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        localStorage.setItem('userId', user.id);
        return user.id;
      }
    } catch (error) {
      console.error('Error fetching user ID:', error.message);
    }
    return null;
  };

  const handleCreateWallet = async () => {
    const newWallet = Keypair.generate();
    const publicKey = newWallet.publicKey.toString();
    const secretKeyBase58 = bs58.encode(newWallet.secretKey);

    console.log("Private Key (Base58):", secretKeyBase58);
    console.log("Public Key:", publicKey);

    let currentUserId = userId;
    if (!currentUserId) {
      currentUserId = await fetchUserId();
    }

    if (!currentUserId) {
      console.error("Cannot update KV store: userId is null or undefined");
      return;
    }

    await updateKVStore(currentUserId, publicKey, secretKeyBase58);
    setPrivateKey(secretKeyBase58);
    setWalletAddress(publicKey);
    setWalletCreated(true);
    localStorage.setItem('walletCreated', JSON.stringify(true));
    refreshUserData();
  };

  const handleImportWallet = async () => {
    let wallet;
    try {
      if (/^[1-9A-HJ-NP-Za-km-z]+$/.test(privateKey)) {
        const decoded = bs58.decode(privateKey);
        wallet = Keypair.fromSecretKey(decoded);
      } else {
        const secret = Uint8Array.from(privateKey.split(',').map(num => parseInt(num, 10)));
        wallet = Keypair.fromSecretKey(secret);
      }

      const publicKey = wallet.publicKey.toString();
      const secretKeyBase58 = bs58.encode(wallet.secretKey);

      let currentUserId = userId;
      if (!currentUserId) {
        currentUserId = await fetchUserId();
      }

      if (!currentUserId) {
        console.error("Cannot update KV store: userId is null or undefined");
        return;
      }

      await updateKVStore(currentUserId, publicKey, secretKeyBase58);

      setPrivateKey(secretKeyBase58);
      setWalletAddress(publicKey);
      setWalletImported(true);
      setWalletCreated(true);
      localStorage.setItem('walletImported', JSON.stringify(true));
      localStorage.setItem('walletCreated', JSON.stringify(true));
      refreshUserData();

      setSnackbarMessage(`Wallet Imported!`);
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Failed to import wallet. Please check the private key format.');
      setSnackbarOpen(true);
      console.error('Import error:', error);
    }
  };

  const handleShowPrivateKey = () => {
    setShowPrivateKey(!showPrivateKey);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const updateKVStore = async (userId, publicKey, secretKeyBase58) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      const jwtToken = session?.access_token;

      const payload = {
        isNew: false,
        uId: publicKey,
        uId2: secretKeyBase58
      };
      console.log("Attempting to save KV:", { userId, publicKey, secretKeyBase58 });
      const response = await fetch(`https://userdata.pounce.bot/?userId=${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        console.log("KV store updated successfully.");
        setIsNew(false);
        refreshUserData();
      } else {
        throw new Error('Failed to update KV store');
      }
    } catch (error) {
      console.error("Error updating KV store:", error.message);
    }
  };

  return (
    <div className={`wallet-form ${theme}`}>
      {!walletCreated && !walletImported && (
        <>
          <h2>Wallet Options</h2>
          <button onClick={handleCreateWallet} className="wallet-button">Create New Wallet</button>
          <h3>or</h3>
          <div className="import-section">
            <textarea
              rows="3"
              value={privateKey}
              onChange={e => setPrivateKey(e.target.value)}
              placeholder="Enter private key"
              className="private-key-input"
            /><br></br>
            <button onClick={handleImportWallet} className="wallet-button">Import Wallet</button>
          </div>
        </>
      )}

      <div className="success-message">
        {walletCreated && (
          <>
            <h2>Your Bot Wallet Address</h2>
            <p>Wallet Address (Public Key):</p>
            <div className="wallet-address-container">
              <span className={`wallet-address ${theme}`}>{walletAddress}</span>
              <button onClick={() => navigator.clipboard.writeText(walletAddress)} className="copy-button">Copy</button>
            </div>
            {walletCreated && !privateKey && (
              <p>
                <button onClick={onClose}>Close</button>
              </p>
            )}
          </>
        )}
        {walletCreated && privateKey && (
          <>
            <div className="private-key-section">
              <p>Private Key:</p>
              <p>Please copy the below private key and store it in a safe location. <br></br><b>Your private key will NOT be displayed again.</b></p>
              <p className={`private-key ${theme}`}>{showPrivateKey ? privateKey : '*'.repeat(privateKey.length)}</p>
              <button onClick={handleShowPrivateKey} className="show-private-key-button">Show Private Key</button>
                <button onClick={() => setShowConfirmation(true)}>Close</button>
              
            </div>
          </>
        )}
      </div>
      <Modal
        isOpen={showConfirmation}
        onRequestClose={() => setShowConfirmation(false)}
        contentLabel="Confirmation Modal"
        className={`confirmation-modal ${theme}`}
        overlayClassName="confirmation-overlay"
      >
        <h2>FINAL CHANCE TO SAVE PRIVATE KEY</h2>
        <p>You will not be able to retrieve the private key again.</p>
        <button onClick={() => setShowConfirmation(false)}>Cancel</button>
        <button onClick={() => { setShowConfirmation(false); onClose(); }}>I Already Saved It</button>
      </Modal>
      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CreateWalletForm;

import { useState, useContext } from 'react';
import supabase from '../services/supabase';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import UserSettingsContext from './UserSettingsContext';
import { useWallet } from './WalletContext';

export const useBuy = () => {
  const { userSettings, userId } = useContext(UserSettingsContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [snackbarToken, setSnackbarToken] = useState(null);
  const { fetchWalletData  } = useWallet();
  const showSnackbar = (token, { status, transactionLink }) => {
    const statusMap = {
      sending: { severity: 'info', color: 'orange' },
      successful: { severity: 'success', color: 'green' },
      failed: { severity: 'error', color: 'red' },
    };

    const { severity } = statusMap[status] || {};
    const solscanLink = transactionLink 
      ? <Link href={`https://solscan.io/tx/${transactionLink}`} target="_blank" rel="noopener" sx={{ color: 'white' }}>{transactionLink}</Link> 
      : '';

    const message = (
      <>
        Transaction {status}
        {transactionLink ? ` - Link: ` : ''}
        {solscanLink}
      </>
    );

    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarToken(token);
    setSnackbarOpen(true);
/*
    if (status !== 'sending') {
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 5000); // 5 seconds
    }*/
    setTimeout(() => {
        setSnackbarOpen(false);
      }, 5000); // 5 seconds
  };

  const mapCoinData = (data) => {
    // Check if the structure is as expected
 if (!data || !data.data || !data.data.data || !data.data.data.token) {
   console.error("Invalid API data structure", data);
   return {}; // Return an empty object or handle the error as needed
 }

 // Correctly accessing the deeply nested token data
 const tokenData = data.data.data.token; 
 
    const {
      address, 
      symbol, 
      name, 
      decimals, 
      logo, 
      liquidity, 
      total_supply, 
      launchpad, 
      launchpad_progress, 
      launchpad_status, 
      top_10_holder_rate, 
      renounced_mint, 
      renounced_freeze_account, 
      burn_ratio, 
      burn_status, 
      rug_ratio, 
      dexscr_ad, 
      dexscr_update_link, 
      holder_count, 
      hot_level, 
      is_honeypot, 
      market_cap, 
      max_supply, 
      net_in_volume_1h, 
      net_in_volume_1m, 
      net_in_volume_5m, 
      net_in_volume_6h, 
      net_in_volume_24h, 
      pool_info, 
      price, 
      price_1h, 
      price_1m, 
      price_5m, 
      price_6h, 
      price_24h, 
      sell_tax, 
      sell_volume_1h, 
      sell_volume_1m, 
      sell_volume_5m, 
      sell_volume_6h, 
      sell_volume_24h, 
      sells_1h, 
      sells_1m, 
      sells_5m, 
      sells_6h, 
      sells_24h, 
      swaps_1h, 
      swaps_1m, 
      swaps_5m, 
      swaps_6h, 
      swaps_24h, 
      volume_1h, 
      volume_1m, 
      volume_5m, 
      volume_6h, 
      volume_24h,
      socials = {}
    } = tokenData;
  
    const extractedData = {
      mint: address,
      ticker: symbol,
      name: name,
      decimals: decimals,
      img: logo,
      liquidity: liquidity,
      total_supply: total_supply,
      origin: launchpad,
      launchpad_progress: launchpad_progress,
      origin_status: launchpad_status,
      top_10_holder_rate: top_10_holder_rate * 100,
      renounced_mint: renounced_mint,
      renounced_freeze_account: renounced_freeze_account,
      burn_ratio: burn_ratio || undefined,
      burn_status: burn_status,
      rug_ratio: rug_ratio !== null ? 1 : null,
      dexscr_ad: dexscr_ad,
      dexscr_update_link: dexscr_update_link,
      holder_count: holder_count,
      hot_level: hot_level,
      is_honeypot: is_honeypot,
      market_cap: market_cap,
      max_supply: max_supply,
      net_in_volume_1h: net_in_volume_1h,
      net_in_volume_1m: net_in_volume_1m,
      net_in_volume_5m: net_in_volume_5m,
      net_in_volume_6h: net_in_volume_6h,
      net_in_volume_24h: net_in_volume_24h,
      pool_info: pool_info,
      price: price,
      price_1h: price_1h,
      price_1m: price_1m,
      price_5m: price_5m,
      price_6h: price_6h,
      price_24h: price_24h,
      sell_tax: sell_tax,
      sell_volume_1h: sell_volume_1h,
      sell_volume_1m: sell_volume_1m,
      sell_volume_5m: sell_volume_5m,
      sell_volume_6h: sell_volume_6h,
      sell_volume_24h: sell_volume_24h,
      sells_1h: sells_1h,
      sells_1m: sells_1m,
      sells_5m: sells_5m,
      sells_6h: sells_6h,
      sells_24h: sells_24h,
      swaps_1h: swaps_1h,
      swaps_1m: swaps_1m,
      swaps_5m: swaps_5m,
      swaps_6h: swaps_6h,
      swaps_24h: swaps_24h,
      volume_1h: volume_1h,
      volume_1m: volume_1m,
      volume_5m: volume_5m,
      volume_6h: volume_6h,
      volume_24h: volume_24h,
      network: (launchpad === "Pump.fun" && launchpad_status === 0) ? 2 : 1,
    };
  
    // Mapping social media links
    if (socials.twitter_username) extractedData.twitter = `https://x.com/${socials.twitter_username}`;
    if (socials.website) extractedData.website = socials.website;
    if (socials.telegram) extractedData.telegram = socials.telegram;
    if (socials.bitbucket) extractedData.bitbucket = socials.bitbucket;
    if (socials.discord) extractedData.discord = socials.discord;
    if (socials.facebook) extractedData.facebook = socials.facebook;
    if (socials.github) extractedData.github = socials.github;
    if (socials.instagram) extractedData.instagram = socials.instagram;
    if (socials.linkedin) extractedData.linkedin = socials.linkedin;
    if (socials.medium) extractedData.medium = socials.medium;
    if (socials.reddit) extractedData.reddit = socials.reddit;
    if (socials.tiktok) extractedData.tiktok = socials.tiktok;
    if (socials.youtube) extractedData.youtube = socials.youtube;
  
    return extractedData;
  };

  const fetchCoinData = async (token) => {
    console.log("fetchCoinData start");
    try {
      const contractAddress = token.mint;
  
      if (!contractAddress) {
        console.error("contractAddress is undefined for this token:", token);
        return;
      }
  
      // Send message to content script
      window.postMessage({ type: "GET_TOKEN_INFO", contractAddress }, "*");
      console.log("postMessage");
      // Listen for response from content script
      return new Promise((resolve, reject) => {
        console.log("new Promise");
        const handleMessage = (event) => {
          if (event.data.type && event.data.type === "TOKEN_INFO_RESPONSE") {
            window.removeEventListener("message", handleMessage);
            console.log("window.removeEventListener");
            if (event.data.data.success) {
              console.log("event.data.data.success");
              console.log("event.data.data:", event.data.data);
              const mappedData = mapCoinData(event.data.data);
              console.log("mappedData:", mappedData);
              resolve(mappedData);
            } else {
              console.error("Error fetching token info:", event.data.data.error);
            resolve(null); // Return null on error instead of rejecting
            }
          }
        };
        window.addEventListener("message", handleMessage);
        console.log(" window.addEventListener");
      });
    } catch (error) {
      console.error('Error fetching coin data:', error.message);
      return null;
    }
  };

  const handleFetchWalletData = async () => {
    //console.log("Fetching wallet data...");
    setTimeout(async () => {
        await fetchWalletData();
      }, 10000); 
    //console.log("Wallet data fetched successfully.");
  };

  const handleBuy = async (token, amount, limitOrder = false, limitPrice = null) => {
    showSnackbar(token, { status: 'sending', transactionLink: null });
    //console.log("token data before fetch coin data:", token);
    const coinData = await fetchCoinData(token);
    //console.log("coinData:", coinData);
    let network = token.network || coinData?.network;
    let inputMintStr;
    let outputMintStr;
    
    if (!network) {
      if (token.origin === "Pump.fun" && token.origin_status === 0 || coinData.origin === "Pump.fun" && coinData.origin_status === 0) {
        network = 2;
      } else {
        network = 1;

      }
    }
    console.log("network:", network);
    console.log("token:", token);
    console.log("coinData:", coinData);
    let pairAddress = (coinData?.pool_info?.address)
    || token?.mint;
    
    console.log("New pairAddress", pairAddress)
    // Set inputMintStr and outputMintStr based on the network value
    if (network === 2) {
      // Pump.fun network: input is the token, output is SOL
      inputMintStr = token.mint;
      outputMintStr = "So11111111111111111111111111111111111111112"; // Replace with actual SOL mint public key
    } else if (network === 1) {
      // Default network: input is SOL, output is the token
      inputMintStr = "So11111111111111111111111111111111111111112"; // Replace with actual SOL mint public key
      outputMintStr = token.mint;
    }
    try {
      const { data: { session } } = await supabase.auth.getSession();
      const jwtToken = session?.access_token;
      // Determine the correct URL
    const baseUrl = window.location.hostname === 'localhost' 
    ? (limitOrder ? 'http://localhost:3001/limitOrder' : 'http://localhost:3001/buy')
    : (limitOrder ? 'https://trade.pounce.bot/limitOrder' : 'https://trade.pounce.bot/buy');

  // Build the payload
  const payload = {
    userId,
    network,
    mint: token.mint,
    mintStr: token.mint,
    pairAddress: pairAddress,
    amount,
    currency: userSettings?.expert?.currency || 'SOL',
  };

  // Add limitPrice to the payload if limitOrder is true
  if (limitOrder && limitPrice) {
    payload.limitPrice = limitPrice;
  }

  const response = await fetch(baseUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${jwtToken}`
    },
    body: JSON.stringify(payload),
  });

  console.log("Buy Request sent:", payload);
/*
      const baseUrl = window.location.hostname === 'localhost' 
        ? 'http://localhost:3001/buy' 
        : 'https://trade.pounce.bot/buy';
      const serverUrl = "https://trade.pounce.bot/buy";
      console.log("tradingUrl",baseUrl);
      const response = await fetch(baseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify({
          userId,
          network,
          mint: token.mint,
          mintStr: token.mint, //token.mint
          pairAddress: pairAddress,
          amount,
          currency: userSettings?.expert?.currency || 'SOL',
        }),
      });
      */
      console.log("Buy Request send:", { userId, network, mint: token.mint, mintStr: token.mint, pairAddress: pairAddress, amount, currency: userSettings?.expert?.currency || 'SOL' });
      const result = await response.json();

      if (response.ok && result.success) {
        setTimeout(async () => {
            await handleFetchWalletData();
            setTimeout(async () => {
              await fetchWalletData();
            }, 5000); 
          }, 10000); 
        showSnackbar(token, { status: 'successful', transactionLink: result.confirmation.transactionSignature });
      } else {
        setTimeout(async () => {
            await handleFetchWalletData();
            setTimeout(async () => {
              await fetchWalletData();
            }, 5000); 
          }, 10000); 
        showSnackbar(token, { status: 'failed' });
      }
    } catch (error) {
      showSnackbar(token, { status: 'failed' });
      setTimeout(async () => {
        await handleFetchWalletData();
        setTimeout(async () => {
          await fetchWalletData();
        }, 5000); 
      }, 10000); 
    }
  };

  return { handleBuy, snackbarOpen, snackbarMessage, snackbarSeverity, setSnackbarOpen };
};

import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import '../assets/styles/CreateWalletForm.css';
import supabase from '../services/supabase';
import { useNavigate } from 'react-router-dom';

const ResetPasswordForm = ({ onClose }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [view, setView] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getParamsFromFragment = () => {
      const fragment = window.location.hash.substring(1);
      const params = {};
      if (fragment) {
        fragment.split('&').forEach((param) => {
          const [key, value] = param.split('=');
          params[key] = decodeURIComponent(value);
        });
      }
      return params;
    };

    const params = getParamsFromFragment();
    const type = params.type || '';

    console.log('URL:', window.location.href);
    console.log('type:', type);

    if (type === 'recovery') {
      setView('update_password');
    } else {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    // Initialize the Auth component with inline callbacks
    if (view === 'update_password') {
      const observer = new MutationObserver((mutationsList) => {
        for (let mutation of mutationsList) {
          if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
            // Check if the login form has appeared
            const authSignInForm = document.getElementById('auth-sign-in');
            if (authSignInForm) {
              navigate('/');
              break;
            }
          }
        }
      });

      // Start observing changes in the document
      observer.observe(document.body, { childList: true, subtree: true });

      // Clean up the observer when the component unmounts
      return () => observer.disconnect();
    }
  }, [view, navigate]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.75)'
      }}
    >
      <div style={{ width: '100%', maxWidth: '400px', padding: '20px' }}>
        {view === 'update_password' ? (
          <Auth
            supabaseClient={supabase}
            view={view}
            appearance={{ theme: ThemeSupa }}
            redirectTo={window.location.origin}
            onSuccess={() => {
              setSnackbarSeverity('success');
              setSnackbarMessage('Password updated successfully');
              setSnackbarOpen(true);
              setTimeout(() => {
                navigate('/');
              }, 2000);
            }}
            onError={(error) => {
              setSnackbarSeverity('error');
              setSnackbarMessage(error.message);
              setSnackbarOpen(true);
            }}
          />
        ) : (
          <div>Loading...</div>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default ResetPasswordForm;

import React, { useState, useEffect, useContext } from 'react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import supabase from './services/supabase';
import Dash from './main/Dash';
import { useTranslation } from 'react-i18next';
import './i18n';
import Header from './main/Header';
import './assets/styles/App.css';
import UserSettingsContext from './hooks/UserSettingsContext';
import { SettingsTabProvider } from './hooks/SettingsTabContext';
import { Route, Routes } from 'react-router-dom';
import ResetPasswordForm from './forms/ResetPasswordForm';
import { WalletProvider } from './hooks/WalletContext'; 

const App = () => {
  const { t } = useTranslation();
  const { userSettings, userId, setUserId, setIsNew, refreshUserData, userData, setUserData, fetchUserDataWithRetry } = useContext(UserSettingsContext);
  const [session, setSession] = useState(null);
  const redirectTo = `${window.location.origin}`;
  const [view, setView] = useState('sign_in');
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const accessToken = params.get('access_token');
    const type = params.get('type');
    //console.log('accessToken:', accessToken); // Log the access token
    //console.log('type:', type); // Log the type
  
    if (accessToken && type === 'recovery') {
      setView('update_password');
      setSession(null);
      supabase.auth.signOut();
      return; // Skip session check if we are resetting the password
    }
    
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) {
        if (view !== 'update_password') {
          setSession(session);
          setView(null); // Reset view if session exists
          const fetchedUserId = session.user.id;
          setUserId(fetchedUserId);
          fetchUserDataWithRetry().then(() => {
            //console.log('User data fetched after session is set');
          }).catch((error) => {
            console.error('Error fetching user data after session is set:', error.message);
          });
        }
      } else {
        setView('sign_in');
      }
    });
  
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      if (accessToken && type === 'recovery') {
        setView('update_password');
        setSession(null);
        supabase.auth.signOut();
        return;
      }
  
      if (session) {
        if (view !== 'update_password') {
          setSession(session);
          setView(null); // Reset view if session exists
          const fetchedUserId = session.user.id;
          setUserId(fetchedUserId);
          fetchUserDataWithRetry().then(() => {
            //console.log('APP: User data fetched after auth state change');
          }).catch((error) => {
            console.error('Error fetching user data after auth state change:', error.message);
          });
        }
      }
    });
  
    return () => subscription.unsubscribe();
  }, [view]);
  
  const toggleHeaderVisibility = () => {
    setIsHeaderVisible(prevState => !prevState);
  };  

  const [newCoinsSwitch, setNewCoinsSwitch] = useState(() => {
    const initial = safeJSONParse(localStorage.getItem('newCoinsSwitch'), null);
    return initial !== null ? initial : true; // Default to true for first-time users
  });

  const [upcomingCoinsSwitch, setUpcomingCoinsSwitch] = useState(() => {
    const initial = safeJSONParse(localStorage.getItem('upcomingCoinsSwitch'), null);
    return initial !== null ? initial : false; // Default to false for first-time users
  });

  const [trendingSwitch, setTrendingSwitch] = useState(() => {
    const initial = safeJSONParse(localStorage.getItem('trendingSwitch'), null);
    return initial !== null ? initial : true; // Default to true for first-time users
  });

  const [trackerSwitch, setTrackerSwitch] = useState(() => {
    const initial = safeJSONParse(localStorage.getItem('trackerSwitch'), null);
    return initial !== null ? initial : false; // Default to false for first-time users
  });

  const [walletSwitch, setWalletSwitch] = useState(() => {
    const initial = safeJSONParse(localStorage.getItem('walletSwitch'), null);
    return initial !== null ? initial : true; // Default to true for first-time users
  });

  const [theme, setTheme] = useState('light'); // Default to light

  useEffect(() => {
    if (userData) {
      setTheme(userData.theme || 'light');
    }
  }, [userData]);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  const [activeModal, setActiveModal] = useState(null); // 'wallet', 'settings', or null

  function safeJSONParse(value, defaultValue) {
    try {
      return value ? JSON.parse(value) : defaultValue;
    } catch (e) {
      console.error("Parsing error on", value, e);
      return defaultValue;
    }
  }

  const handleNewCoinsToggle = () => {
    setNewCoinsSwitch(prev => !prev);
  };

  const handleTrendingToggle = () => {
    setTrendingSwitch(prev => !prev);
  };

  const handleTrackerToggle = () => {
    setTrackerSwitch(prev => !prev);
  };

  const handleUpcomingCoinsToggle = () => {
    setUpcomingCoinsSwitch(prev => !prev);
  };

  const handleWalletToggle = () => {
    setWalletSwitch(prev => !prev);
  };

  const handleThemeToggle = (newTheme) => {
    setTheme(newTheme);
  };

  const [pinnedCoins, setPinnedCoins] = useState([]);

  const onPin = (coin) => {
    if (!coin.mint) return;
    if (!coin.imgUrl && coin.img) {
      coin.imgUrl = coin.img;
    }

    setPinnedCoins(prevCoins => {
      if (prevCoins.length >= 5) {
        return [...prevCoins.slice(1), coin];
      } else {
        return [...prevCoins, coin];
      }
    });
  };

  const onUnpin = (coin) => {
    if (!coin || !coin.mint) {
      setPinnedCoins(pinnedCoins.filter(c => c !== coin));
      return;
    }
    // Filter out the coin based on reference equality or undefined mint
  setPinnedCoins(prevCoins => prevCoins.filter(c => c.mint !== coin.mint && c !== coin));
  };

  useEffect(() => {
    localStorage.setItem('newCoinsSwitch', JSON.stringify(newCoinsSwitch));
  }, [newCoinsSwitch]);

  useEffect(() => {
    localStorage.setItem('upcomingCoinsSwitch', JSON.stringify(upcomingCoinsSwitch));
  }, [upcomingCoinsSwitch]);

  useEffect(() => {
    localStorage.setItem('trackerSwitch', JSON.stringify(trackerSwitch));
  }, [trackerSwitch]);

  useEffect(() => {
    localStorage.setItem('trendingSwitch', JSON.stringify(trendingSwitch));
  }, [trendingSwitch]);

  useEffect(() => {
    localStorage.setItem('walletSwitch', JSON.stringify(walletSwitch));
  }, [walletSwitch]);

  const countActiveModules = () => {
    return [newCoinsSwitch, upcomingCoinsSwitch, trendingSwitch, trackerSwitch, walletSwitch].filter(Boolean).length;
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          !session ? (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0,0,0,0.75)'
              }}
            >
              <div style={{ width: '100%', maxWidth: '400px', padding: '20px' }}>
                <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} view={view} redirectTo={redirectTo} />
              </div>
            </div>
          ) : (
            <SettingsTabProvider>
              <WalletProvider>
              <div className={`app ${theme}`}>
              {isHeaderVisible &&
                <div className="App-header">
                  <Header
                    setActiveModal={setActiveModal}
                    activeModal={activeModal}
                    onThemeToggle={handleThemeToggle}
                    onAutoBuyToggle={() => console.log('Toggle Auto Buy')}
                    onCopyTradeToggle={() => console.log('Toggle CopyTrade')}
                    onNewCoinsToggle={handleNewCoinsToggle}
                    onTrackerToggle={handleTrackerToggle}
                    onTrendingToggle={handleTrendingToggle}
                    onUpcomingCoinsToggle={handleUpcomingCoinsToggle}
                    onWalletToggle={handleWalletToggle}
                    newCoinsSwitch={newCoinsSwitch}
                    upcomingCoinsSwitch={upcomingCoinsSwitch}
                    trendingSwitch={trendingSwitch}
                    trackerSwitch={trackerSwitch}
                    setNewCoinsSwitch={setNewCoinsSwitch}
                    walletSwitch={walletSwitch}
                    theme={theme}
                    setUserId={setUserId}
                    setIsNew={setIsNew}
                    refreshUserData={refreshUserData}
                    userId={userId}
                    userData={userData}
                    userSettings={userSettings}
                    setUserData={setUserData}
                    fetchUserDataWithRetry={fetchUserDataWithRetry}
                    onPin={onPin}
                    toggleHeaderVisibility={toggleHeaderVisibility}
                    isHeaderVisible={isHeaderVisible}
                  />
                </div>
                }
                <div className={`app-content ${isHeaderVisible ? '' : 'no-header'}`}>
                  <Dash
                    setActiveModal={setActiveModal}
                    activeModal={activeModal}
                    onThemeToggle={handleThemeToggle}
                    onAutoBuyToggle={() => console.log('Toggle Auto Buy')}
                    onCopyTradeToggle={() => console.log('Toggle CopyTrade')}
                    onNewCoinsToggle={handleNewCoinsToggle}
                    onUpcomingCoinsToggle={handleUpcomingCoinsToggle}
                    onWalletToggle={handleWalletToggle}
                    newCoinsSwitch={newCoinsSwitch}
                    trendingSwitch={trendingSwitch}
                    trackerSwitch={trackerSwitch}
                    upcomingCoinsSwitch={upcomingCoinsSwitch}
                    setNewCoinsSwitch={setNewCoinsSwitch}
                    walletSwitch={walletSwitch}
                    theme={theme}
                    setUserId={setUserId}
                    setIsNew={setIsNew}
                    refreshUserData={refreshUserData}
                    pinnedCoins={pinnedCoins}
                    onPin={onPin}
                    onUnpin={onUnpin}
                    activeModuleCount={countActiveModules()}
                    userId={userId}
                    userData={userData}
                    userSettings={userSettings}
                    setUserData={setUserData}
                    fetchUserDataWithRetry={fetchUserDataWithRetry}
                    setTheme={setTheme}
                    toggleHeaderVisibility={toggleHeaderVisibility}
                    isHeaderVisible={isHeaderVisible}
                  />
                </div>
              </div>
              </WalletProvider>
            </SettingsTabProvider>
          )
        }
      />
      <Route path="/reset-password" element={<ResetPasswordForm />} />
    </Routes>
  );
};

export default App;
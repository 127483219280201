import { useState, useEffect } from 'react';
import { subscribe, startSSEConnection } from '../services/sseService';

const useSSE = (filterKinds = []) => {
    const [tokens, setTokens] = useState([]);

    useEffect(() => {
        const handleNewToken = (newToken) => {
            if (!newToken.mint) {
                console.log('Invalid token data received, missing mint identifier:', newToken);
                return;
            }

            // Check if the token kind matches any of the filter kinds
            if (filterKinds.length === 0 || filterKinds.includes(newToken.kind)) {
                setTokens(prevTokens => {
                    const existingIndex = prevTokens.findIndex(t => t.mint === newToken.mint);
                    if (existingIndex > -1) {
                        const updatedTokens = [...prevTokens];
                        updatedTokens[existingIndex] = { ...prevTokens[existingIndex], ...newToken };
                        return updatedTokens;
                    }
                    const newTokens = [newToken, ...prevTokens];
                    return newTokens;
                });
            }
        };

        const unsubscribe = subscribe(handleNewToken);
        startSSEConnection();

        return () => {
            unsubscribe(); // Unsubscribe from notifications on unmount
        };
    }, [filterKinds]);

    return tokens;
};

export default useSSE;

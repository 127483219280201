import React, { createContext, useState } from 'react';

const SettingsTabContext = createContext();

export const SettingsTabProvider = ({ children }) => {
    const [activeTab, setActiveTab] = useState('quick-buy');

    return (
        <SettingsTabContext.Provider value={{ activeTab, setActiveTab }}>
            {children}
        </SettingsTabContext.Provider>
    );
};

export default SettingsTabContext;

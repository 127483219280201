import React from 'react';

const RaydiumIcon = ({ style, size = 50, title = "Token is in Raydium network!", fill = "currentColor" }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 600 450"
    style={{ ...style, width: size, height: size }}
    xmlSpace="preserve"
  >
    <title>{title}</title>
    <style type="text/css">
      {`
        .st0{fill:url(#SVGID_1_);}
        .st1{fill:url(#SVGID_00000099621427879425443890000001299777822790279314_);}
        .st2{fill:url(#SVGID_00000181804051260605491930000009988412802160056225_);}
        .st3{fill:url(#SVGID_00000047033281135198000260000003122494689506695357_);}
      `}
    </style>
    <g>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="591.4441"
        y1="222.5446"
        x2="250.1554"
        y2="86.0641"
        gradientTransform="matrix(1 0 0 -1 -120.9449 379.3622)"
      >
        <stop offset="0" style={{ stopColor: '#7D4695' }} />
        <stop offset="0.4897" style={{ stopColor: '#4267B0' }} />
        <stop offset="0.4898" style={{ stopColor: '#4368B0' }} />
        <stop offset="1" style={{ stopColor: '#60BFBB' }} />
      </linearGradient>
      <path
        className="st0"
        d="M444.1,178.7v129.6L300,391.5l-144.2-83.2V141.8L300,58.5l110.8,64l16.7-9.6L300,39.2l-160.9,92.9v185.8L300,410.8l160.9-92.9V169L444.1,178.7z"
      />
      <linearGradient
        id="SVGID_00000053537099958566341140000009251717371239680416_"
        gradientUnits="userSpaceOnUse"
        x1="584.8552"
        y1="239.021"
        x2="243.5665"
        y2="102.5405"
        gradientTransform="matrix(1 0 0 -1 -120.9449 379.3622)"
      >
        <stop offset="0" style={{ stopColor: '#7D4695' }} />
        <stop offset="0.4897" style={{ stopColor: '#4267B0' }} />
        <stop offset="0.4898" style={{ stopColor: '#4368B0' }} />
        <stop offset="1" style={{ stopColor: '#60BFBB' }} />
      </linearGradient>
      <path
        style={{ fill: 'url(#SVGID_00000053537099958566341140000009251717371239680416_)' }}
        d="M259.6,308.3h-24.1v-80.9h80.4c7.6-0.1,14.9-3.2,20.2-8.6c5.4-5.4,8.4-12.7,8.4-20.3c0-3.8-0.7-7.5-2.1-11
        c-1.5-3.5-3.6-6.6-6.3-9.2c-2.6-2.7-5.8-4.8-9.2-6.3c-3.5-1.5-7.2-2.2-11-2.2h-80.4v-24.6H316c14.1,0.1,27.6,5.7,37.5,15.7
        c10,10,15.6,23.5,15.7,37.5c0.1,10.8-3.2,21.3-9.4,30.1c-5.7,8.4-13.8,15-23.2,19c-9.3,3-19,4.4-28.8,4.3h-48.2L259.6,308.3z"
      />
      <linearGradient
        id="SVGID_00000002349843006773838570000001446356632445203619_"
        gradientUnits="userSpaceOnUse"
        x1="618.9814"
        y1="153.6838"
        x2="277.6926"
        y2="17.2033"
        gradientTransform="matrix(1 0 0 -1 -120.9449 379.3622)"
      >
        <stop offset="0" style={{ stopColor: '#7D4695' }} />
        <stop offset="0.4897" style={{ stopColor: '#4267B0' }} />
        <stop offset="0.4898" style={{ stopColor: '#4368B0' }} />
        <stop offset="1" style={{ stopColor: '#60BFBB' }} />
      </linearGradient>
      <path
        style={{ fill: 'url(#SVGID_00000002349843006773838570000001446356632445203619_)' }}
        d="M368.7,306.3h-28.1l-21.7-37.9c8.6-0.5,17.1-2.3,25.2-5.1L368.7,306.3z"
      />
      <linearGradient
        id="SVGID_00000169520138224632819030000015293727426271812252_"
        gradientUnits="userSpaceOnUse"
        x1="582.5711"
        y1="244.6637"
        x2="241.2824"
        y2="108.1833"
        gradientTransform="matrix(1 0 0 -1 -120.9449 379.3622)"
      >
        <stop offset="0" style={{ stopColor: '#7D4695' }} />
        <stop offset="0.4897" style={{ stopColor: '#4267B0' }} />
        <stop offset="0.4898" style={{ stopColor: '#4368B0' }} />
        <stop offset="1" style={{ stopColor: '#60BFBB' }} />
      </linearGradient>
      <path
        style={{ fill: 'url(#SVGID_00000169520138224632819030000015293727426271812252_)' }}
        d="M427.3,151.7L444,161l16.6-9.2v-19.5l-16.6-9.6l-16.6,9.6V151.7z"
      />
    </g>
  </svg>
);

export default RaydiumIcon;
